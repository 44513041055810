import React, { Component } from 'react';
import NumberHelper from '@pdl/app/util/NumberHelper';
import DateHelper from '@pdl/app/util/DateHelper';

export default class CategoryTransaction extends Component {

  render() {
    let item = this.props.transaction;
    let iconBad = item.icon;
    let icon = iconBad;
    if (iconBad === 'info') {
      icon = 'fa-' + iconBad;
    }
    let amount = null;
    if (item.type === 'spend') {
      amount = <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>-{NumberHelper.currency(Math.abs(item.amount, true))}</span>
    } else {
      amount = <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>{NumberHelper.currency(item.amount, true)}</span>
    }
    return (
      <li className={'notificationItem transactionItem statements '}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, flexGrow: 1 }}>
          <span className='iconHolder' style={{ width: 40, height: 40 }}>
            <i className='fa fa-info' aria-hidden={true} style={{ color: 'white', fontSize: 21 }}></i>
          </span>
          <span className="transaction-title-wrapper" style={{ display: 'flex', flexDirection: 'column', paddingLeft: 3 }}>
            <span className="transaction-title" >{item.name}</span>
            <span className='transaction-date'>{DateHelper.date(item.transactionDate)}</span>
          </span>
        </div>
        <div className="transaction-amount-wrapper" >
          {amount}
        </div>
      </li>
    )
  }
}
