import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export const SentLink: React.FC<any> = () => {
  return (
    <div>
      <div className="verification-image-signup" />
      <div className="container login-container signup-account-container">
        <img
          src={require('@pdl/app/assets/variants/tillo/images/logo_blue.png')}
          alt="PDL"
          className="login-logo"
          style={{ maxWidth: 200 }}
        />
        <div className="row">
          <div className="col-md login-holder">
            {/* <img
              src={require('@pdl/app/assets/img/sent-link-image.png')}
              alt="PDL"
              className="paper-plane-image"
            /> */}
            <h6 className="signup-link-sent-title ">
              <Trans>We sent you a verification link to your e-mail</Trans>
            </h6>
            <p
              style={{
                marginBottom: 50,
                marginTop: 30,
                textAlign: 'center',
                fontSize: 18
              }}
            >
              <Trans>
                Please click on the link in your mail box, and verify your
                <br />
                account and you can continue with your registration.
              </Trans>
            </p>
            <Link
              to="/"
              className="gold-button"
              style={{ maxWidth: 400, width: '100%', margin: 'auto' }}
            >
              <Trans>OK</Trans>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentLink;
