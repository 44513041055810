import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BottomWaveForCard from '../crud/BottomWaveForCard';

class AppLayoutWithCard extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="screen-layout">
        <div className="app-card">
          <img
            className="logo"
            src={require('../../assets/Launchpad_logoWhite.png')}
            alt="white-logo"
          />
          <div className="child-container">{this.props.children}</div>
          <BottomWaveForCard />
        </div>
        <div className="sign-up-holder">
          <p>New user?</p>
          <Link to="" className="sign-up-link">
            Sign Up
          </Link>
        </div>
      </div>
    );
  }
}

export default AppLayoutWithCard;
