export default class CardService {

  static hasCard(cards) {
    return cards.filter(x => x.status != 'lost').length > 0;
  }

  static getDefaultCard(cards) {
    return cards[0];
  }
}
