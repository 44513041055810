import React from 'react';
import { AppButton } from '@pdl/app';
import { Trans } from 'react-i18next';
import AppImage from '@pdl/app/components/images/AppImage';
import { connect } from 'react-redux';

class ConfirmReceiver extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.state = {};
  }

  render() {
    const { user } = this.props;
    return (
      <>
        <h4 className="text-center">
          <Trans>Is this the person you want to send money to?</Trans>
        </h4>
        <div className="d-flex align-items-center flex-column justify-content-center">
          <AppImage
            src={
              user.image
                ? user.image
                : require('@pdl/app/assets/profile-image-dummy.png')
            }
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
              marginBottom: 10
            }}
          />
          <h4
            style={{
              textAlign: 'center',
              fontSize: 20
            }}
          >
            {user.fullName}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: 100 }}
        >
          <AppButton onClick={() => this.props.nextStep()}>
            <Trans>Yes</Trans>
          </AppButton>
          <AppButton onClick={() => this.props.previousStep()}>
            <Trans>No</Trans>
          </AppButton>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(ConfirmReceiver);
