import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ModalService from '@pdl/app/logic/services/ModalService';

export default class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    if (this.props.inBody) {
      ReactModal.setAppElement('body');
    }
  }

  componentWillUnmount() {}

  getParent(props) {
    return props.inBody
      ? document.body
      : document.querySelector('#modalHolder');
  }

  handleCloseModal() {
    if (this.props.id) {
      ModalService.hideModal(this.props.id);
    }
    this.props.onClose();
  }

  render() {
    const { props } = this;

    return (
      <ReactModal
        isOpen={this.props.show}
        onRequestClose={this.handleCloseModal}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
        portalClassName="react-modal-portal"
        shouldReturnFocusAfterClose
        role="dialog"
        parentSelector={() =>
          this.props.inBody
            ? document.body
            : document.querySelector('#modalHolder')
        }
        style={this.props.style}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}

ModalDialog.defaultProps = {
  style: {},
  onClose: () => null
};
