import CurrencyHelper from '@pdl/app/util/CurrencyHelper';
import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';

export default class TopUpModel extends BaseModel {
  async topUp(card, amount, currency = null) {
    const data = {
      id: card.id,
      amount,
      currency: currency || CurrencyHelper.getCurrency()
    };

    const response: any = await Api.call('top-up', data);

    if (!response.success) {
      throw new Error(response.message);
    }

    return response.data;
  }
}
