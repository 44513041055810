import React, { Component } from 'react';
import { AppRoute } from '@pdl/app';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import AppTopBar from '@pdl/app/components/layout/components/AppTopBar';
import AppSideBar from '@pdl/app/components/layout/components/AppSideBar';
import AppFooter from '@pdl/app/components/layout/components/AppFooter';
import Dashboard from '../../modules/dashboard/Dashboard';
import ProfilePage from '../../modules/profile/ProfilePage';
import EditMyPersonalData from '../../modules/profile/components/EditMyPersonalData';
import CardPage from '../../modules/card/screens/CardPage';
import StatementsPage from '../../modules/statements/StatementsPage';
import NotificationsPage from '../../modules/notifications/NotificationsPage';
import SendMoney from '../../modules/send-money/SendMoney';
import KycPage from '../../modules/kyc/KycPage';
import FaqPage from '../../modules/legal/FaqPage';
import TermsAndConditions from '../../modules/legal/TermsAndConditions';
import PrivacyPolicy from '../../modules/legal/PrivacyPolicy';
import CustomerCare from '../../modules/legal/CustomerCare';

class DefaultLayout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapseSideBar: false
    };
  }

  componentDidUpdate(prevProps: { location: any }) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      window.scrollTo(0, 0);
    }
  }

  onRouteChanged() {
    this.setState({
      collapseSideBar: false
    });
  }

  btnHandler = () => {
    this.setState(state => {
      return {
        ...state,
        collapseSideBar: !state.collapseSideBar
      };
    });
  };

  _getInnerElements() {
    if (!this.props.profile) return null;

    const currentUrl = this.props.match.url;

    return (
      <Switch>
        <AppRoute
          path={`${currentUrl}notifications`}
          component={NotificationsPage}
        />
        <AppRoute path={`${currentUrl}send-money`} component={SendMoney} />
        <AppRoute path={`${currentUrl}statements`} component={StatementsPage} />
        <AppRoute path={`${currentUrl}card`} component={CardPage} />
        <AppRoute path={`${currentUrl}profile`}>
          <Route path={`${currentUrl}profile`} component={ProfilePage} />
        </AppRoute>
        <AppRoute path={`${currentUrl}faq`}>
          <Route path={`${currentUrl}faq`} component={FaqPage} />
        </AppRoute>
        <AppRoute path={`${currentUrl}privacy-policy`}>
          <Route
            path={`${currentUrl}privacy-policy`}
            component={PrivacyPolicy}
          />
        </AppRoute>
        <AppRoute path={`${currentUrl}customer-care`}>
          <Route path={`${currentUrl}customer-care`} component={CustomerCare} />
        </AppRoute>
        <AppRoute path={`${currentUrl}terms-conditions`}>
          <Route
            path={`${currentUrl}terms-conditions`}
            component={TermsAndConditions}
          />
        </AppRoute>
        <AppRoute
          path={`${currentUrl}edit-personal-data`}
          component={EditMyPersonalData}
        />
        <AppRoute
          path="/load-card"
          load={() => import('@pdl/app/modules/top-up/pages/LoadCard')}
          authenticatedOnly
        />

        <AppRoute
          path="/fx-calculator"
          load={() => import('@pdl/app/modules/fx-calculator/pages/FxCalculator')}
          authenticatedOnly
        />

        <AppRoute exact path={`${currentUrl}`} component={Dashboard} />
      </Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.profile) return null;

    const currentUrl = this.props.match.url;

    if (this.props.profile.kycState === 'pending_documents_from_user') {
      return (
        <Switch>
          <AppRoute
            path={`${currentUrl}`}
            component={KycPage}
            authenticatedOnly
          />
        </Switch>
      );
    }

    const innerElements = this._getInnerElements();

    return (
      <div style={{ position: 'relative' }}>
        <div className="phone-menu-holder" onClick={() => this.btnHandler()}>
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
        </div>
        <AppTopBar />
        <AppSideBar isOpen={this.state.collapseSideBar} />
        <div className="inner">
          <div className="dashboard-main">{innerElements}</div>

          <div className="clear" />
        </div>
        <AppFooter />
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(DefaultLayout);
