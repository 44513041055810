import React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import AccountService from '../account/AccountService';
import SendMoneyService from './SendMoneyService';
import CustomerReference from './components/CustomerReference';
import ConfirmReceiver from './components/ConfirmReceiver';
import EnterAmount from './components/EnterAmount';
import ConfirmTransfer from './components/ConfirmTransfer';
import SuccessComponent from './components/SuccessComponent';

class SendMoney extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.state = {
      step: 1,
      user: {},
      data: {},
      transfer: {}
    };
  }

  componentDidMount() {
    const mainAccount = AccountService.getMainAccount(this.props.accounts);

    SendMoneyService.initTransfer(mainAccount);
  }

  getUser(user: any) {
    this.setState({
      user
    });
  }

  getData(data: any) {
    this.setState({
      data
    });
  }

  getTransfer(transfer: any) {
    this.setState({
      transfer
    });
  }

  nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1
    }));
  };

  previousStep = () => {
    this.setState(prevState => ({
      step: prevState.step - 1
    }));
  };

  anotherTransfer = () => {
    this.setState({
      step: 1,
      user: {},
      data: {},
      transfer: {}
    });
  };

  changeContent() {
    switch (this.state.step) {
      case 1:
        return (
          <CustomerReference
            history={this.props.history}
            nextStep={this.nextStep}
            getUser={(user: any) => this.getUser(user)}
          />
        );
      case 2:
        return (
          <ConfirmReceiver
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            user={this.state.user}
          />
        );
      case 3:
        return (
          <EnterAmount
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            user={this.state.user}
            dataForTransfer={(data: any) => this.getData(data)}
          />
        );
      case 4:
        return (
          <ConfirmTransfer
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            data={this.state.data}
            successTransfer={(tranfer: any) => this.getTransfer(tranfer)}
          />
        );
      case 5:
        return (
          <SuccessComponent
            anotherTransfer={this.anotherTransfer}
            transfer={this.state.transfer}
          />
        );
      default:
        return (
          <CustomerReference
            history={this.props.history}
            nextStep={this.nextStep}
            getUser={(user: any) => this.getUser(user)}
          />
        );
    }
  }

  render() {
    const content = this.changeContent();

    return (
      <div className="appContainer" style={{ justifyContent: 'flex-start' }}>
        <div className="container">
          <div className="col-xl-7 m-auto profile-container">
            <h1
              style={{
                textAlign: this.state.step > 1 ? 'center' : 'left',
                textTransform: 'uppercase'
              }}
            >
              <Trans>Send money</Trans>
            </h1>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(SendMoney);
