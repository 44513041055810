import React, { Component } from 'react';
import LegalModel from './model/LegalModel';

export default class TermsAndConditions extends Component {
  state = {
    terms: null
  };

  componentDidMount() {
    new LegalModel().getTerms().then(terms => {
      this.setState({
        terms
      });
    });
  }

  render() {
    const content = this.state.terms && this.state.terms.data ? this.state.terms.data.text : null;
    let contentElement = null;
    if (content) {
      contentElement = this.state.terms.data.text;
    }
    const modified = this.state.terms ? this.state.terms.modified : null;
    return (
      <div className="appContainer">
        <h1>Terms & Conditions</h1>
        <div className="container">
          <div className="card-wrapper">
            <div className="col-12 white-box no-shadow">
              <div
                className="legal-description cms-content"
                style={{ background: 'transparent', paddingTop: 0 }}
                dangerouslySetInnerHTML={{ __html: contentElement }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
