import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon, SwitchButton } from '@pdl/app';
import NumberHelper from '@pdl/app/util/NumberHelper';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import Api from '@pdl/app/logic/api/Api';
import AuthService from '@pdl/app/logic/services/AuthService';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import i18n from '../../../../../i18n';
import CardModel from '../models/CardModel';

class CardDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      autoTopUp: false,
      notifications: false,
      disableInternet: false
    };
  }

  handleAutoTopUpModal(prevState) {
    this.setState({ autoTopUp: !prevState.autoTopUp });
    // if (!this.state.autoTopUp === true) {
    //   this.setState({ showModal: true });
    // }
  }

  handleDisableInternet(prevState) {
    this.setState({ disableInternet: !prevState.disableInternet });
  }

  handleNotifications(id: any, status: any) {
    LoadingHelper.executeAction(
      () => {
        return new CardModel().notificationsForPaymentDevice(id, status);
      },
      {
        successMessage: status
          ? i18n.t('You enabled Payment Device notifications')
          : i18n.t('You disabled Payment Device notifications'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && (
              <AppIcon
                name="fa.power-off"
                style={{ fontSize: 150, color: '#FF6E6E' }}
              />
            )
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  handleFxNotifications(status: any) {
    LoadingHelper.executeAction(
      () => {
        return this.changeStatusFxNotifications(status);
      },
      {
        successMessage: status
          ? i18n.t('Your FX conversion notifications is on')
          : i18n.t('Your FX conversion notifications is off'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: 'An error ocurred, please try again later',
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && (
              <AppIcon
                name="fa.power-off"
                style={{ fontSize: 150, color: '#FF6E6E' }}
              />
            )
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  handleFxReportNotifications(status: any) {
    LoadingHelper.executeAction(
      () => {
        return this.changeStatusFxReportNotifications(status);
      },
      {
        successMessage: status
          ? i18n.t('Your monthly FX exchange reports is on')
          : i18n.t('Your monthly FX exchange reports is off'),
        loadingMessage: i18n.t('Loading...'),
        failureMessage: 'An error ocurred, please try again later',
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return (
            !status && (
              <AppIcon
                name="fa.power-off"
                style={{ fontSize: 150, color: '#FF6E6E' }}
              />
            )
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  async changeStatusFxNotifications(status: any) {
    const response = await Api.call('auth/user/notifications/fx/configure', {
      enabled: !!status
    });

    return response;
  }

  async changeStatusFxReportNotifications(status: any) {
    const response = await Api.call(
      'auth/user/notifications/fx-report/configure',
      {
        enabled: !!status
      }
    );

    return response;
  }

  render() {
    const { card, profile } = this.props;
    return (
      <div className="row details-holder">
        <div className="top-line" />
        <div className="col-md-5">
          <ul>
            <li>
              <Trans>Status: </Trans>
              <span>
                {card.statusText}{' '}
                <AppIcon
                  name="fa.circle"
                  style={{
                    fontSize: 10,
                    bottom: 2,
                    position: 'relative',
                    color: card.statusText === 'OK' ? '#3FD184' : '#FE8585'
                  }}
                />
              </span>
            </li>
            <li>
              <Trans>Total Balance: </Trans>
              <span>{NumberHelper.currency(card.balance, card.currency)}</span>
            </li>
            <li>
              <Trans>PAN: </Trans>
              <span>{card.pan}</span>
            </li>
            <li>
              <Trans>Expires: </Trans>
              <span>{card.expires}</span>
            </li>
            <li>
              <Link to="/terms-conditions" className="link-for-terms">
                <Trans>Terms & Conditions</Trans>
              </Link>
            </li>
            <li>
              <Link to="/fx-calculator" className="link-for-terms">
                <Trans>Exchange Fee Calculator</Trans>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-7">
          <ul className="control-card">
            {/* <li>
              Auto Top up
              <SwitchButton
                isChecked={this.state.autoTopUp}
                onChange={prevState => this.handleAutoTopUpModal(prevState)}
              />
            </li> */}
            {/* <li>
              <Trans>Disable use on the internet</Trans>
              <SwitchButton
                isChecked={this.state.disableInternet}
                onChange={prevState => this.handleDisableInternet(prevState)}
              />
            </li> */}
            <li>
              <Trans>Notifications</Trans>
              <SwitchButton
                isChecked={!!card.notificationsEnabled}
                onChange={(val: any) => this.handleNotifications(card.id, val)}
              />
            </li>

            <li>
              <Trans>Receive FX conversion notifications</Trans>
              <SwitchButton
                isChecked={!!profile.fxNotifications}
                onChange={(val: any) => this.handleFxNotifications(val)}
              />
            </li>
            <li>
              <Trans>Receive monthly FX exchange reports</Trans>
              <SwitchButton
                isChecked={!!profile.fxReportNotifications}
                onChange={(val: any) => this.handleFxReportNotifications(val)}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(CardDetails);
