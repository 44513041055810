import BaseModel from '@pdl/app/logic/model/BaseModel';
import moment from 'moment';
import i18n from 'i18n';

export default class EditProfileFormModel extends BaseModel {
  static getTitles() {
    return [
      { id: 'Mr', name: 'Mr' },
      { id: 'Mrs', name: 'Mrs' },
      { id: 'Miss', name: 'Miss' },
      { id: 'Ms', name: 'Ms' },
      { id: 'Dr', name: 'Dr' },
      { id: 'Reverend', name: 'Reverend' }
    ];
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/profile/change-request';
  }

  // /**
  //  *
  //  * @param {Object} currentInfo Current user info
  //  * @param {Object} updatedInfo Updated user info
  //  */
  compareProfileFields(storeInfo, updatedInfo) {
    const currentInfo = {
      ...storeInfo
      // birthDay: moment(storeInfo.birthDate).format('YYYY-MM-DD')
    };
    const compareFields = [
      // 'title',
      'firstName',
      'lastName',
      'city',
      'countryId',
      'addressOne',
      'addressTwo',
      'postCode',
      // 'birthDate',
      'mobile',
      'houseNameNumber'
    ];

    const changes = [];

    for (let i = 0; i < compareFields.length; i++) {
      const key = compareFields[i];
      if (currentInfo[key] !== updatedInfo[key]) {
        changes.push({
          field: key,
          oldValue: currentInfo[key],
          newValue: updatedInfo[key]
        });
      }
    }

    return changes.length !== 0;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      // title: {
      //   presence: {
      //     message: '^Please select a title',
      //     allowEmpty: false
      //   }
      // },
      firstName: {
        presence: {
          message: `^${i18n.t('Please enter a first name')}`,
          allowEmpty: false
        }
      },
      lastName: {
        presence: {
          message: `^${i18n.t('Please enter a last name')}`,
          allowEmpty: false
        }
      },
      // birthDate: {
      //   presence: {
      //     message: '^Please select a date of birth',
      //     allowEmpty: false
      //   }
      // },
      addressOne: {
        presence: {
          message: `^${i18n.t('Please enter a address line 1')}`,
          allowEmpty: false
        }
      },
      // addressTwo: {
      //   presence: {
      //     message: '^Please enter a address line 2',
      //     allowEmpty: false
      //   }
      // },
      houseNameNumber: {
        presence: {
          message: `^${i18n.t('Please enter house name/number')}`,
          allowEmpty: false
        }
      },
      mobile: {
        presence: {
          message: `^${i18n.t('Please enter a mobile phone')}`,
          allowEmpty: false
        }
      },
      city: {
        presence: {
          message: `^${i18n.t('Please enter a city name')}`,
          allowEmpty: false
        }
      },
      postCode: {
        presence: {
          message: `^${i18n.t('Please enter a post code')}`,
          allowEmpty: false
        }
      },
      countryId: {
        presence: {
          message: `^${i18n.t('Please select a country')}`,
          allowEmpty: false
        }
      }
    };
  }

  customValidation(record) {
    const errors = [];

    // const birthDay = record.birthDate;
    // if (moment().diff(moment(birthDay), 'years') < 18) {
    //   errors.push({
    //     field: 'birthDay',
    //     message: 'You must be 18 years or older'
    //   });
    // }

    const { mobile } = record;
    const valueWithoutUnderscore = mobile.split('_').join(' ');
    const trimMobile = valueWithoutUnderscore.trim();

    const regex = /^\+[0-9 ]+$/g;
    const found = trimMobile.match(regex);

    if (!found && trimMobile.length > 0) {
      errors.push({
        field: 'mobile',
        message: i18n.t(
          'Mobile phone format invalid, use following format: +xxxxxxxxxxx...'
        )
      });
    }

    if (trimMobile.length < 8 && trimMobile.length > 0) {
      errors.push({
        field: 'mobile',
        message: i18n.t('Mobile phone is invalid')
      });
    }

    // const checkTwoFirstDigits = trimMobile.substring(0, 2);
    // if (checkTwoFirstDigits !== '07' && trimMobile.length === 11) {
    //   errors.push({
    //     field: 'mobile',
    //     message: 'Your mobile number must start with 07...'
    //   });
    // }

    return errors;
  }
}
