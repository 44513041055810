import React from 'react';
import DateHelper from '../../../util/DateHelper';
import { AppIcon } from '@pdl/app';
import NotificationModel from '../models/NotificationModel';

export default class NotificationListItem extends React.Component<any, any> {
  render() {
    let { notification } = this.props;

    return (
      <li className="notificationItem">
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!notification.read ? (
            <i
              className="fa fa-circle "
              aria-hidden={true}
              style={{
                color: '#4f3bd0',
                opacity: 0.4,
                fontSize: 10,
                alignSelf: 'flex-start'
              }}
            ></i>
          ) : null}
        </span>
        <div style={{ flex: 1, paddingRight: 70 }}>
          <p>{notification.notificationTitle}</p>
          <p>{notification.notificationPreview}</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'flex-end'
          }}
        >
          {/* <span className='iconHolder notification' style={{ minWidth: 40, flex: 1 }}>
            <div style={{ background: 'white', width: 30, height: 30, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AppIcon name={NotificationModel.getIcon(notification)} style={{ fontSize: 25 }} />
            </div>
          </span> */}
          <span style={{ fontSize: 12, color: '#656565' }}>
            {DateHelper.date(notification.created)}
          </span>
        </div>
      </li>
    );
  }
}
