import BaseModel from '../../../logic/model/BaseModel';

export default class ForgotPasswordModel extends BaseModel {
  /**
   * Get submit URL for the model
   */
  getSubmitUrl() {
    return 'auth/password-reset';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      email: {
        presence: {
          message: '^Please enter an email address'
        },
        email: {
          message: '^Please enter a valid email address'
        }
      }
    };
  }
}
