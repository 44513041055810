import CountryModel from '@pdl/app/logic/model/CountryModel';
import Api from '@pdl/app/logic/api/Api';

export default class LocationService {
  static async getCountries() {
    const result = await new CountryModel().fetchFlat();

    if (result) {
      return result;
    }

    return [];
  }

  static async findAddress(postcode) {
    const result = await Api.call('location/address/suggest', {
      postcode
    });

    if (result.success) {
      return result.data;
    }

    return [];
  }
}
