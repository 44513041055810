/* eslint-disable default-case */
import React from 'react';
import StandardTransaction from './transactions/StandardTransaction';
import CountryTransaction from './transactions/CountryTransaction';
import CategoryTransaction from './transactions/CategoryTransaction';
import MerchantTransaction from './transactions/MerchantTransaction';

export default class TransactionItem extends React.PureComponent {
  render() {
    const item = this.props.transaction;

    switch (this.props.type) {
      case 'all':
        return <StandardTransaction transaction={item} />;
      case 'country':
        return <CountryTransaction transaction={item} />;
      case 'category':
        return <CategoryTransaction transaction={item} />;
      case 'merchant':
        return <MerchantTransaction transaction={item} />;
    }
  }
}
