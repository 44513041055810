import React from 'react';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import $ from 'jquery';
import { connect } from 'react-redux';
import ModalService from '@pdl/app/logic/services/ModalService';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import AuthService from '@pdl/app/logic/services/AuthService';
import { AppIcon, AppButton } from '@pdl/app';
import CardModel from '@pdl/app/modules/card/models/CardModel';
import CardService from '@pdl/app/modules/card/CardService';
import NumberHelper from '@pdl/app/util/NumberHelper';
import AccountService from '@pdl/app/modules/account/AccountService';
import AddPaymentCardForm from './AddPaymentCardForm';
import TopUpModel from '../models/TopUpModel';
import { Trans } from 'react-i18next';

export const NAME = 'ACTIVATE_CARD';

class PaymentMethodModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      amount: props.amount,
      show: true,
      showAddForm: false,
      digits: ['', '', '', ''],
      activeCard: null
    };
  }

  onClose() {
    ModalService.hideModal();
  }

  nextFocus(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const digits = [...this.state.digits];

    digits[index] = e.target.value;

    const regex = /^[0-9\b]+$/;

    const num = regex.test(digits[index]);

    if (e.target.value === '' || num) {
      this.setState({
        digits
      });
    } else {
      return;
    }

    if (e.target.value.length === 1) {
      $(e.target)
        .next()
        .focus();
    } else {
      $(e.target)
        .prev()
        .focus();
    }
  }

  _selectActiveCard(paymentCard) {
    this.setState({
      activeCard: paymentCard,
      showAddForm: false
    });
  }

  _triggerTopUp() {
    const { activeCard, amount } = this.state;

    if (!activeCard) return;

    let is3D = false;

    LoadingHelper.executeAction(
      () => {
        return new Promise((resolve, reject) => {
          try {
            new TopUpModel()
              .topUp(activeCard, amount)
              .then(response => {
                if (response.redirect) {
                  is3D = true;
                  // TODO: Show 3DS modal
                  ModalService.showModal('THREEDS_MODAL', {
                    redirect: response.redirect,
                    onSuccess: () => {
                      this.props.onSuccess();
                    },
                    onFailure: () => {
                      if (this.props.onFailure) this.props.onFailure();
                    }
                  });
                  resolve(false);
                } else {
                  this.props.onSuccess();
                  resolve(true);
                }
              })
              .catch(e => {
                reject(e);
              });
          } catch (e) {
            reject(e);
          }
        });
      },
      {
        showSuccess: () => !is3D,
        successMessage: 'Top up successful',
        loadingMessage: 'Topping up…',
        failureMessage: 'Top up failed, please try with another card',
        beforeClose: () => {}
      }
    );
  }

  _getAvailablePaymentMethods() {
    const { paymentCards } = this.props;

    if (!paymentCards || paymentCards.length === 0) {
      return (
        <div>
          <em>No payment methods available</em>
        </div>
      );
    }

    return (
      <div className="payment-card-list">
        {paymentCards.map(paymentCard => {
          const isActive =
            this.state.activeCard &&
            this.state.activeCard.id === paymentCard.id;
          return (
            <div
              className={`single-payment-card${isActive ? ' selected' : ''}`}
              onClick={() => this._selectActiveCard(paymentCard)}
            >
              <span>
                <Trans>Card ending </Trans>
                <strong>******{paymentCard.last4}</strong>
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  _getExistingCards() {
    if (this.state.showAddForm) return null;

    const { accounts } = this.props;

    const account = AccountService.getMainAccount(accounts);
    if (!account) return null;

    return (
      <div className="transaction-modal">
        <div className="close-btn">
          <AppIcon
            name="fa.times"
            style={{
              fontSize: 24,
              color: 'rgba(0,0,0,0.4)',
              cursor: 'pointer'
            }}
            onClick={() => ModalService.hideModal()}
          />
        </div>
        <div className="inner-container">
          <h1>
            <Trans>Select payment method</Trans>
          </h1>
          {this._getAvailablePaymentMethods()}
          <hr />

          <div className="row">
            <div className="col-md-6">
              <a
                href="javascript:void(0)"
                onClick={() => this.setState({ showAddForm: true })}
              >
                <div className="add-new-card button">
                  <p>
                    + <Trans>Add new card</Trans>
                  </p>
                </div>
              </a>
            </div>
            <div className="col-md-6">
              <AppButton onClick={() => this._triggerTopUp()}>
                <Trans>PAY</Trans>
              </AppButton>
            </div>
          </div>
          <p className="entered-amount">
            <Trans>Amount: </Trans>
            {NumberHelper.currency(this.props.amount, account.currency)}
          </p>
        </div>
      </div>
    );
  }

  _getAddCardForm() {
    if (!this.state.showAddForm) return null;

    return (
      <AddPaymentCardForm
        amount={this.state.amount}
        onCancel={() => this.setState({ showAddForm: false })}
        onFailure={() => this.setState({ showAddForm: true })}
        onSuccess={() => this.setState({ showAddForm: false })}
      />
    );
  }

  render() {
    const user = this.props.profile;

    if (!user) return null;

    return (
      <ModalDialog show={this.state.show} onClose={() => this.onClose()}>
        <div style={{ backgroundColor: 'white' }}>
          {this._getExistingCards()}
          {this._getAddCardForm()}
        </div>
        {/* </div> */}
      </ModalDialog>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    paymentDevices: state.paymentDevices,
    paymentCards: state.paymentCards,
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(PaymentMethodModal);
