import BaseModel from '@pdl/app/logic/model/BaseModel';
import AppStore from '@pdl/app/logic/store/AppStore';
import Config from '@pdl/app/config';

export default class AccountModel extends BaseModel {
  static isFullyVerified(profile) {
    return profile.kycLevel === 'full';
  }

  static getProfileImage(profile = null) {
    if (!profile) {
      profile = AppStore.getState().auth.profile;
    }

    if (!profile || !profile.imagePath) {
      return require('@pdl/app/assets/profile-image-dummy.png');
    }

    return Config.publicUrl + profile.imagePath;
  }
}
