import {
  refreshAuthAction,
  logoutTriggerAction
} from '@pdl/app/modules/auth/AuthActions';
import AppStore from '../store/AppStore';

export default class Auth {
  static refreshTimeout: any;

  /**
   * Refresh auth
   */
  static async refreshAuth() {
    await AppStore.dispatch(refreshAuthAction());
  }

  static async logout() {
    await AppStore.dispatch(logoutTriggerAction());
  }

  // static async poolStatusUpdate() {
  //   if(this.refreshTimeout) {
  //     clearTimeout(this.refreshTimeout);
  //     this.refreshTimeout = null;
  //   }

  //   try {
  //     if(AppStore.getState().auth.token) {
  //       let response = await Api.call(
  //         'member/status-update',
  //       );

  //       if(response.success) {
  //         AppStore.dispatch(statusUpdateReceivedAction(response.data));
  //       }
  //     }
  //   } catch(e) {}

  //   this.refreshTimeout = setTimeout(() => {
  //     Auth.poolStatusUpdate();
  //   }, 5000);
  // }
}
