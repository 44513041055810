import BaseModel from '@pdl/app/logic/model/BaseModel';
import i18n from '../../../../../i18n';

export default class SendMoneyModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'transfer/info';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      customerReference: {
        presence: {
          message: `^${i18n.t('Please enter Tillo customer reference')}`
        }
      }
    };
  }
}
