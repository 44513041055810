import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '@pdl/app/components/images/ProfileImage';
import DateHelper from '@pdl/app/util/DateHelper';
import { Link } from 'react-router-dom';
import AccountInfoSection from './components/AccountInfoSection';
import SecuritySettings from './components/SecuritySettings';
import SupportContacts from '../legal/components/SupportContacts';
import ThirdPartyAccessList from '../sca/components/ThirdPartyAccessList';
import { Trans } from 'react-i18next';

class Profile extends React.PureComponent<any, any> {
  render() {
    const { profile } = this.props;

    return (
      <div className="appContainer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="white-box">
                <AccountInfoSection profile={profile} editImage />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="white-box">
                <p style={{ fontSize: 21 }}>
                  <Trans>Security and Login</Trans>
                </p>
                <p style={{ fontSize: 18, marginTop: 20 }}>
                  <Trans>Change password</Trans>
                </p>
                <SecuritySettings />
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: 25 }}>
            <div className="col-lg-6 terms-holder">
              <div className="profile-container">
                <h5 className="privacy-text">
                  <Trans>Read our Privacy Policy </Trans>
                  <Link to="/privacy-policy" className="terms-link">
                    <Trans>here</Trans>
                  </Link>
                </h5>
              </div>
            </div>
            <div className="col-lg-6 terms-holder  mt-3 mt-lg-0">
              <div className="profile-container">
                <h5 className="privacy-text">
                  <Trans>Read our Terms & Conditions </Trans>
                  <Link to="/terms-conditions" className="terms-link">
                    <Trans>here</Trans>
                  </Link>
                </h5>
              </div>
            </div>
            <div className="col-lg-6 terms-holder  mt-3">
              <div className="profile-container">
                <h5>
                  <Trans>Customer Care</Trans>
                </h5>
                {/* For Customer Care click{' '} */}
                {/* <Link to="/customer-care" className="terms-link">
                    here
                  </Link> */}
                <SupportContacts />
              </div>
            </div>
            <div className="col-lg-12 terms-holder mt-3">
              <div className="profile-container">
                <h5>
                  <Trans>Authorised Third Party Access</Trans>
                </h5>
                <ThirdPartyAccessList />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(Profile);
