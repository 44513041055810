import React from 'react';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import ModalService from '@pdl/app/logic/services/ModalService';
import NumberHelper from '@pdl/app/util/NumberHelper';
import DateHelper from '@pdl/app/util/DateHelper';
import { AppIcon, AppButton } from '@pdl/app';
import { Trans } from 'react-i18next';

export default class LoadModal extends React.Component<any, any> {
  render() {
    let profile = this.props.profile;

    return (
      <ModalDialog id={'LOAD_CARD_MODAL'} show={true}>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name={'fa.times'}
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <h3 className="load-title">
              <Trans>Load Card</Trans>
            </h3>
            <p className="load-subtitle">
              <Trans>
                Use the details below to load your card directly from your bank
                account
              </Trans>
            </p>
            <div className="row justify-content-center align-items-center load-info">
              <div className="col-sm-6 column first-line">
                <span>Payee</span>
                <span>Sort code </span>
                <span>Account No</span>
                <span>IBAN </span>
                <span>SWIFT/BICSWIFT</span>
                <span>Currency</span>
              </div>
              <div className="col-sm-6 column second-line">
                <span>Pannovate</span>
                <span>12-34-56</span>
                <span>1234567890</span>
                <span>PK99BANK0123456789</span>
                <span>AMADAGB11</span>
                <span>{profile.currency}</span>
              </div>
            </div>

            <div className="customer-no">
              <p>Your Customer no</p>
              <div className="ref-number-holder">
                <span>{profile.customerReference}</span>
              </div>
              <h6>
                Please include this number in the reference of your payment so
                that we can ensure prompt credit to your account
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 0.5,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppButton customstyle={{ maxWidth: 450, maxHeight: 60 }}>
              SEND REQUEST
            </AppButton>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
