import BaseModel from './BaseModel';

export default class CountryModel extends BaseModel {
  // /**
  //  * Get table name
  //  */
  // getTableName() {
  //   return 'countries';
  // }

  /**
   * Get list url
   */
  getListUrl() {
    return 'countries/list';
  }
}
