import React from 'react';
import { connect } from 'react-redux';
import Finish from './components/Finish';
import UploadPhoto from './components/UploadPhoto';

export const KycPage: React.FC<any> = props => {
  // TODO: Figure out which one to show here

  let innerComponent: any = null;

  if (
    props.profile.kycStatus === 'pending_documents_from_user' ||
    props.profile.kycState === 'pending_documents_from_user'
  ) {
    innerComponent = <UploadPhoto />;
  } else {
    innerComponent = <Finish />;
  }

  return <div>{innerComponent}</div>;
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(KycPage);
