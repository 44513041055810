import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import FormHandler from 'AdminApp/common/script/services/crud/FormHandler';
// import ModalDialog from '@pdl/app/elements/modal/ModalDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { AppButton } from '@pdl/app';
import FormComponent from './FormComponent';

export default class FormSubmitWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // infoMessage: false,
      // toastOpacity: 0,
      textMessage: ''
    };
    // Initial state
    this.formHandler = props.formHandler;

    this.formHandler.on('submitSuccess', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState({
        // infoMessage: true,
        textMessage:
          customMessage || data.message || 'The form is successfully saved.'
        // redirect: true
      });
    });

    this.formHandler.on('submitError', data => {
      const customMessage = this.props.getModalMessage(data);
      this.setState(
        {
          // infoMessage: true,
          textMessage:
            customMessage ||
            data.message ||
            'Oops, something is wrong, you can not finish this action.'
          // toastOpacity: 1
        },
        () => {
          ToastHelper.show(this.state.textMessage);
        }
      );
    });

    this.formHandler.on('inputChange', () => {
      this.setState({
        // infoMessage: false,
        textMessage: ''
        // toastOpacity: 0
      });
    });
  }

  // onClose() {
  //   this.setState({
  //     showInfoModal: false
  //   });
  // }

  _getButtonContent() {
    if (this.formHandler.state.isLoading || this.state.redirect) {
      return <CircularProgress className="circle-progress" size={30} />;
    }
    return <span>{this.props.buttonText}</span>;
  }

  _getSubmitButton() {
    if (this.props.hideSubmit) return null;

    const buttonContent = this._getButtonContent();
    return (
      <div
        className={`submitButtonWrapper ${this.props.changeSubmitButtonWrapper}`}
      >
        <div className={`form-button-container ${this.props.buttonContainer}`}>
          <AppButton className={this.props.buttonClass} type="submit">
            {buttonContent}
          </AppButton>
        </div>
      </div>
    );
  }

  _afterSubmitButton() {
    if(!this.props.afterSubmit) return null;
    
    return this.props.afterSubmit();
  }

  render() {
    const { formHandler } = this.props;

    // let message = <div className='toast-mesaage' style={{ opacity: this.state.toastOpacity }}>
    //   <p style={{ textAlign: 'center', color: '#ff5959' }}>{this.state.textMessage}</p>
    //   <div className='close-icon'>
    //     <AppIcon onClick={() => this.setState({ toastOpacity: 0 })} name={'fa.times-circle-o'} style={{ fontSize: 25 }} />
    //   </div>
    // </div>
    // let message = toast(this.state.textMessage ? this.state.textMessage[0] : null);

    return (
      <div>
        <FormComponent formHandler={formHandler} onSubmit={this.props.onSubmit}>
          {this.props.children}

          {/* <div className='formRow' style={{ marginTop: 20, display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}> */}
          {this._getSubmitButton()}
          {/* {this.state.infoMessage === true ? message : ''} */}
          {this._afterSubmitButton()}
        </FormComponent>
      </div>
    );
  }
}

FormSubmitWrapper.defaultProps = {
  buttonText: '',
  getModalMessage: () => null,
  click: () => null,
  onSubmit: null,
  buttonContainer: '',
  changeSubmitButtonWrapper: ''
};
