import React from 'react';
import { Provider } from 'react-redux';
import AppToastAlert from '@pdl/app/components/elements/AppToastAlert';
import store from './logic/store/AppStore';
import AppContainer from './AppContainer';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.scss';
import 'bootstrap';

export interface LaunchPadAppProps {
  configuration: any;
}

export const LaunchPadApp: React.FC<any> = () => {
  return (
    <Provider store={store}>
      <AppContainer />
      <AppToastAlert />
    </Provider>
  );
};

export default LaunchPadApp;
