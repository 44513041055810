import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { Trans } from 'react-i18next';
import FaqModel from './model/FaqModel';

export default class FaqPage extends Component {
  state = {
    faq: []
  };

  componentDidMount() {
    new FaqModel().getFaq().then(faq => {
      this.setState({
        faq: faq.items
      });
    });
  }

  _renderAllQuestions() {
    const items = [];
    const { faq } = this.state;
    faq.forEach(item => {
      items.push(
        <Collapsible trigger={item.title}>
          <div
            style={{
              background: 'transparent',
              // paddingTop: 0
              paddingTop: 25
            }}
            dangerouslySetInnerHTML={{ __html: item.content }}
            className="cms-content full-text"
          />
        </Collapsible>
      );
    });
    return items;
  }

  render() {
    const items = this._renderAllQuestions();
    return (
      <div className="appContainer">
        <h1>
          <Trans>FAQ</Trans>
        </h1>
        <div className="container">
          <div className="card-wrapper">
            <div className="col-12 white-box">{items}</div>
          </div>
        </div>
      </div>
    );
  }
}
