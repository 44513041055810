import React from 'react';
import { InputField, FormHandler, FormSubmitWrapper } from '@pdl/app';
import { Link } from 'react-router-dom';
import { AppButton } from '@pdl/app/components';
import NumberHelper from '@pdl/app/util/NumberHelper';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import Auth from '@pdl/app/logic/services/AuthService';
import ModalService from '@pdl/app/logic/services/ModalService';
import PaymentCardModel from '../models/PaymentCardModel';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

export class AddPaymentCardForm extends React.Component<any, any> {
  formHandler: FormHandler;

  constructor(props) {
    super(props);

    this.formHandler = new FormHandler(
      this,
      new PaymentCardModel({
        // cardNumber: '4242 4242 4242 4242',
        // month: 12,
        // year: 2022,
        // cvv: 100
      }),
      {
        cardNumberMask: [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/
        ],
        expirationDateMask: [
          /[0-1]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/
        ],
        cvvMask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
        cardNumBorder: '1px solid gray',
        expDateBorder: '1px solid gray',
        cvvBorder: '1px solid gray'
      }
    );
  }

  _on3DSSuccess() {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  }

  _on3DSFailure() {
    if (this.props.onFailure) {
      this.props.onFailure();
    }
  }

  _validateFields() {
    const { cardNumber, expirationDate, cvv } = this.state.record;
    if (!cardNumber || cardNumber.replace(/\s+/g, '').length !== 16) {
      this.setState({
        cardNumBorder: '1px solid red'
      });
      return false;
    }

    // Card number validatad
    this.setState({
      cardNumBorder: '1px solid gray'
    });

    if (!expirationDate || expirationDate.length !== 7) {
      this.setState({
        expDateBorder: '1px solid red'
      });
      return false;
    }
    const expDate = expirationDate.split('/');
    const date = new Date(expDate[1], expDate[0] - 1);
    if (date < new Date()) {
      this.setState({
        expDateBorder: '1px solid red'
      });
      return false;
    }
    // Expiration date validate
    this.setState({
      expDateBorder: '1px solid gray'
    });

    if (!cvv || cvv.length < 3) {
      this.setState({
        cvvBorder: '1px solid red'
      });
      return false;
    }
    // Cvv field validate
    this.setState({
      cvvBorder: '1px solid gray'
    });

    return true;
  }

  async _addCard() {
    if (!this._validateFields()) return;
    let is3D = false;
    LoadingHelper.executeAction(
      () => {
        return new Promise((resolve, reject) => {
          new PaymentCardModel()
            .addCard(
              this.props.profile,
              this.state.record.cardNumber,
              this.state.record.expirationDate,
              this.state.record.cvv
            )
            .then(response => {
              if (typeof response === 'string') {
                is3D = true;

                // TODO: Show 3DS modal
                ModalService.showModal('THREEDS_MODAL', {
                  redirect: response,
                  onSuccess: () => {
                    this._on3DSSuccess();
                  },
                  onFailure: () => {
                    this._on3DSFailure();
                  }
                });

                resolve(true);
              } else {
                this._on3DSSuccess();
                resolve(true);
              }
            })
            .catch(e => {
              reject(true);
            });
        });
      },
      {
        showSuccess: () => !is3D,
        successMessage: i18n.t('Card Added!'),
        loadingMessage: i18n.t('Adding card...'),
        failureMessage: i18n.t('An error occurred, please try again later'),
        beforeClose: (status: string) => {
          if (status !== 'failure') {
            Auth.refreshAuth();
          }
        }
      }
    );
  }

  render() {
    const { formHandler } = this;
    return (
      <div className="add-payment-card">
        <p>
          <strong>
            <Trans>Enter your card details</Trans>
          </strong>
        </p>
        <div className="enter-card-details-form">
          <p>
            <Trans>Card Number</Trans>
          </p>
          <MaskedInput
            style={{ border: this.state.cardNumBorder, paddingLeft: 5 }}
            id="cardNumber"
            name="cardNumber"
            className="order-card-input-group card-number"
            placeholder="XXXX XXXX XXXX XXXX"
            mask={this.state.cardNumberMask}
            showMask={false}
            guide={false}
            keepCharPosition
            onChange={e => formHandler.handleInputChange(e)}
            value={this.state.record.cardNumber}
          />
        </div>
        <div className="enter-card-details-form ">
          <p>
            <Trans>Expiration date</Trans>
          </p>
          <MaskedInput
            style={{ border: this.state.expDateBorder, paddingLeft: 5 }}
            id="expirationDate"
            name="expirationDate"
            placeholder="MM/YYYY"
            mask={this.state.expirationDateMask}
            showMask={false}
            guide={false}
            keepCharPosition
            onChange={e => formHandler.handleInputChange(e)}
            value={this.state.record.expirationDate}
          />
        </div>
        <div className="enter-card-details-form">
          <p>
            <Trans>CVC2/CVV2 code</Trans>
          </p>
          <MaskedInput
            style={{ border: this.state.cvvBorder, paddingLeft: 5 }}
            id="cvv"
            name="cvv"
            placeholder="XXX"
            mask={this.state.cvvMask}
            showMask={false}
            guide={false}
            keepCharPosition
            onChange={e => formHandler.handleInputChange(e)}
            value={this.state.record.cvv}
          />
        </div>
        {/* <p className="entered-amount">Amount: {NumberHelper.currency(this.props.amount)}</p> */}
        <div className="enter-card-detils-button-wrapper">
          <button
            type="button"
            onClick={() => this.props.onCancel()}
            className="gold-border-button medium-button"
          >
            <Trans>cancel</Trans>
          </button>
          <a
            href="javascript:void(0)"
            onClick={() => this._addCard()}
            className="gold-button medium-button"
          >
            <Trans>Add card</Trans>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(AddPaymentCardForm);
