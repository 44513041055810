import BaseModel from '../../../logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';
import moment from 'moment';
import PasswordService from '@pdl/app/logic/services/PasswordService';
import _ from 'lodash';
import i18n from '../../../../../i18n';

export default class RegistrationModel extends BaseModel {
  /**
   * Validate registration hash
   *
   * @param hash Hash retrieved from url
   */
  async validateRegistrationHash(hash) {
    const result = await Api.call('auth/email-verification/check', {
      hash
    });

    if (!result.success) return false;

    return result.data;
  }

  /**
   * Get submit URL for the model
   */
  getSubmitUrl() {
    return 'auth/register';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      title: {
        presence: {
          message: '^Please select a title',
          allowEmpty: false
        }
      },
      firstName: {
        presence: {
          message: '^Please enter your first name'
        }
      },
      lastName: {
        presence: {
          message: '^Please enter your last name'
        }
      },
      password: {
        presence: {
          message: '^Please enter the password'
        }
      },
      repeatedPassword: {
        presence: {
          message: '^Please repeat the password'
        }
      },
      addressOne: {
        presence: {
          message: '^Please enter your street'
        }
      },
      houseNameNumber: {
        presence: {
          message: '^Please enter your house number/name'
        }
      },
      postCode: {
        presence: {
          message: '^Post code is required!'
        }
      },
      countryId: {
        presence: {
          message: '^Please select the country'
        }
      },
      city: {
        presence: {
          message: '^Please enter the city'
        }
      },
      mobile: {
        presence: {
          message: '^Please enter your mobile phone'
        }
      },
      birthDate: {
        presence: {
          message: '^Please enter your birh date'
        }
      },
      privacyPolicyAgreed: {
        presence: {
          message: '^You must aggree to privacy policy'
        }
      },
      termsAndConditionsAgreed: {
        presence: {
          message: '^You must aggree to T&Cs'
        }
      },
      gdprAgreed: {
        presence: {
          message: '^You must aggree to our GDPR policy'
        }
      }
    };
  }

  customValidation(record) {
    const errors = [];

    try {
      PasswordService.validatePassword(record.password);
    } catch (e) {
      errors.push({
        field: 'password',
        message: e.message
      });
    }

    const birthDay = record.birthDate;
    if (!birthDay || !moment(birthDay).isValid()) {
      errors.push({
        field: 'birthDate',
        message: i18n.t('Invalid date!')
      });
    }

    if (moment().diff(moment(birthDay), 'years') < 18) {
      errors.push({
        field: 'birthDate',
        message: i18n.t('You must be 18 years or older')
      });
    }

    const { mobile } = record;
    const valueWithoutUnderscore = mobile.split('_').join(' ');
    const trimMobile = valueWithoutUnderscore.trim();

    const regex = /^\+[0-9 ]+$/g;
    const found = trimMobile.match(regex);

    if (!found) {
      errors.push({
        field: 'mobile',
        message: i18n.t(
          'Mobile phone format invalid, use following format: +xxxxxxxxxxx...'
        )
      });
    }

    if (record.password !== record.repeatedPassword) {
      errors.push({
        field: 'repeatedPassword',
        message: i18n.t("Passwords don't match!")
      });
    }

    // Validate SOF
    const { funds } = record;

    const doesContainTrue = objToCheck => {
      const keys = Object.keys(objToCheck);
      for (let i = 0; i < keys.length; i++) {
        if (objToCheck[keys[i]]) return true;
      }
      return false;
    };

    if (!funds || _.isEmpty(funds) || !doesContainTrue(funds)) {
      errors.push({
        field: 'funds',
        message: i18n.t('Please select at least one source of funds')
      });
    }

    return errors;
  }
}
