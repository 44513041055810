import numeral from 'numeral';

import CurrencyHelper from './CurrencyHelper';

export function currency(value = 0, symbol = false, skipDecimal = false) {
  const negative = value < 0;
  const val = skipDecimal
    ? numeral(Math.abs(value)).format('0,0')
    : numeral(Math.abs(value)).format('0,0.00');

  let returnValue = val;

  // if (!value % 1 == 0) {
  //   returnValue = parseFloat(Math.round(value * 100) / 100).toFixed(2);
  // } else {
  // }
  // if (value % 1 == 0) {
  //   returnValue = parseInt(value, 10);
  // }

  if (symbol) {
    let currencySymbol = null;
    if (symbol === true) {
      currencySymbol = CurrencyHelper.getCurrencySymbol();
    } else {
      currencySymbol = CurrencyHelper.getCurrencySymbol(symbol);
    }

    const beforeOrAfter = CurrencyHelper.beforeOrAfter(currencySymbol);

    returnValue =
      beforeOrAfter == -1
        ? currencySymbol + returnValue
        : returnValue + currencySymbol;
  }

  if (negative) {
    returnValue = `-${returnValue}`;
  }

  return returnValue;
}

export function value(val) {
  return numeral(val).value();
}

export function isNonZero(value) {
  const convertedValue = numeral(value).value();

  if (!convertedValue || isNaN(convertedValue)) return false;

  if (convertedValue != 0) return true;

  return true;
}

export default {
  isNonZero,
  value,
  currency
};
