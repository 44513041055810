import React from 'react';
import $ from 'jquery';
import SideMenuItem from './SideMenuItem';

export default class AppSideBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      navigation: this.getNavigation(),
      mobileSideState: props.isOpen
    };
  }

  componentDidMount() {
    this.setState({ mobileSideState: this.props.isOpen });
  }

  getNavigation() {
    return [
      {
        label: 'Home',
        icon: require('@pdl/app/assets/img/home-icon.svg'),
        path: '/'
      },
      {
        label: 'Card',
        icon: require('@pdl/app/assets/img/card-icon.svg'),
        path: '/card'
      },
      {
        label: 'Statements',
        icon: require('@pdl/app/assets/img/chart-icon.svg'),
        path: '/statements'
      },
      {
        label: 'Profile',
        icon: require('@pdl/app/assets/img/user-icon.svg'),
        path: '/profile'
      },
      {
        label: 'Faq',
        icon: require('@pdl/app/assets/img/question.png'),
        path: '/faq'
      }
    ];
  }

  static getDerivedStateFromProps(props: { isOpen: any }, state: any) {
    if (props.isOpen !== state.mobileSideState) {
      return {
        mobileSideState: props.isOpen
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  render() {
    const items = [] as any;
    this.state.navigation.forEach((item: any, index: any) => {
      const key = `side-menu-item-${index}`;
      items.push(<SideMenuItem {...item} key={key} />);
    });

    const { mobileSideState } = this.state;
    let mobileClass = '';

    if (mobileSideState) {
      mobileClass = 'mobile-side-bar';
    }

    if (this.state.mobileSideState === true) {
      $('body').css('overflow-y', 'hidden');
    } else if ($(window).width() < 768) {
      $('body').css('overflow-y', 'auto');
    }

    return (
      <div className={`side-bar ${mobileClass}`}>
        {mobileSideState ? <div className="phone-overlay" /> : null}
        {/* <div className="dash-logo"><img src={require('../../../assets/Launchpad_logo.png')} alt="logo" /></div> */}
        <ul className="sideMenuList">{items}</ul>
      </div>
    );
  }
}
