import i18n from 'i18n';
import { toast } from 'react-toastify';

export default class ToastHelper {
  static alertElement = null;

  static defaultProps = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };

  static setAlert(alertElement) {
    this.alertElement = alertElement;
  }

  static success(text, params = {}) {
    toast.success(i18n.t(text), {
      ...this.defaultProps,
      ...params
    });
  }

  static show(text, params = {}) {
    let toastId = params.toastId || text;
    toast.error(i18n.t(text), {
      ...this.defaultProps,
      toastId: toastId,
      ...params
    });
  }
}
