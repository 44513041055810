import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';

export default class TransactionCategoriesModel extends BaseModel {

    getListUrl () {
      return 'account/transactions/categories';
    }

    // async getItems(period, type, limit = null) {
    //   let url = null;
    //   switch(type) {
    //     case 'all':
    //       url = 'account/transactions';
    //     break;
    //     case 'country':
    //       url = 'account/transactions/countries';
    //     break;
    //     case 'merchant':
    //       url = 'account/transactions/merchants';
    //     break;
    //     case 'category':
    //       url = 'account/transactions/categories';
    //     break;
    //   }

    //   let result = await Api.call(url, {
    //     limit
    //   });
    //   return result.data.items;
    // }
}
