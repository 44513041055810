import ukCountry from '../../assets/countries/uk-country.png';
import deCountry from '../../assets/countries/de-country.png';
import sweCountry from '../../assets/countries/swe-country.png';

/**
 * Returns source of country image for given locale
 * @param {Currency} currency - Currency code
 * @returns {string} - Source of country image
 */
export function getLocaleCountry(locale) {
  switch (locale) {
    case 'en':
      return ukCountry;
    case 'de':
      return deCountry;
    case 'swe':
      return sweCountry;
    default:
      return ukCountry;
  }
}
