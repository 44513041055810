import React from 'react';
import { Line, Circle } from 'rc-progress';

export default class AppProgressBar extends React.Component<any, any> {
  
  _getProgressContent() {
    let strokeColor = 'transparent';
    if (this.props.percent !== 0) {
      strokeColor = '#308CF6';
    }
    if (this.props.circle) {
      return (
        <Circle
          percent={this.props.percent}
          trailWidth={2}
          trailColor="rgba(48, 140, 246, 0.2)"
          strokeWidth={2}
          strokeColor={strokeColor}
        />
      );
    }

    return (
      <Line
        percent={this.props.percent}
        trailWidth={2}
        trailColor="rgba(48, 140, 246, 0.2)"
        strokeWidth={2}
        strokeColor={strokeColor}
      />
    );
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        {this._getProgressContent()}
      </div>
    );
  }
}
