import React from 'react';
import { connect } from 'react-redux';
// import hoistNonReactStatics from 'hoist-non-react-statics';

export default function withLocalization(WrappedComponent) {
  const mapStateToProps = state => {
    return {
      locale: state.localization.locale
    };
  };

  const LocalizedComponent = props => {
    return <WrappedComponent {...props} key={props.locale} />;
  };

  return connect(mapStateToProps)(LocalizedComponent);
}
