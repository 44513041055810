import React from 'react';
import { connect } from 'react-redux';
import { AppIcon } from '@pdl/app';
import NumberHelper from '@pdl/app/util/NumberHelper';
import { Link, RouteComponentProps } from 'react-router-dom';
import ModalService from '@pdl/app/logic/services/ModalService';
import LockCardButton from '@pdl/app/components/crud/LockCardButton';
import AccountService from '@pdl/app/modules/account/AccountService';
import { withRouter } from 'react-router';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import { CustomIcon } from '@pdl/app/components';
import Auth from '@pdl/app/logic/services/AuthService';
import RoutingService from '@pdl/app/logic/services/RoutingService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import VerificationService from '@pdl/app/logic/services/VerificationService';
import CardModel from '../../card/models/CardModel';
import CardService from '../../card/CardService';
import WalletChart from './WalletChart';
import { Trans } from 'react-i18next';

interface IProps extends RouteComponentProps<any> {
  presentationOnly?: boolean;
}

class DashbaoardHeader extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadModal: false
    };
  }

  openLoadModal() {
    this.setState({
      loadModal: true
    });
  }

  closeLoadModal() {
    this.setState({
      loadModal: false
    });
  }

  _changeCardLockStatus(val) {
    const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);

    // if(!card.isLive) return;

    LoadingHelper.executeAction(
      () => {
        return new CardModel().changeFreezeStatus(paymentDevice, val);
      },
      {
        successMessage: val ? 'Your card is locked' : 'Your card is active now',
        loadingMessage: val ? 'Locking card...' : 'Unlocking card...',
        failureMessage: 'An error ocurred, please try again later',
        manualClose: true,
        renderIcon: (state, params) => {
          if (state === 'loading') return null;
          return val ? (
            <CustomIcon
              name="card-locked"
              style={{ fontSize: 150, color: '#4f3bd0' }}
            />
          ) : (
            <CustomIcon
              name="card-unlocked"
              style={{ fontSize: 150, color: '#4f3bd0' }}
            />
          );
        },
        beforeClose: () => {
          Auth.refreshAuth();
          RoutingService.preventScroll();
        }
      }
    );
  }

  render() {
    const { profile, accounts } = this.props;

    const verified = VerificationService.isVerified(profile);

    const account = AccountService.getMainAccount(accounts);
    if (!account) return null;

    const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);

    const cardActive = paymentDevice && paymentDevice.isLive;
    // let cardActive = false;

    // const percentage =
    //   parseFloat(paymentDevice.lastMaxBalance) &&
    //   parseFloat(paymentDevice.lastMaxBalance) &&
    //   parseFloat(paymentDevice.balance)
    //     ? (parseFloat(paymentDevice.balance) /
    //         parseFloat(paymentDevice.lastMaxBalance)) *
    //       100
    //     : 0;

    const loadAccount = (
      <div
        onClick={() =>
          verified
            ? this.props.history.push('/load-card')
            : ToastHelper.show(
                'Your account is not verified yet. Please stay tuned.'
              )
        }
        className="send-load-btn-wrapper"
      >
        {' '}
        <div className="send-load-icon-wrapper">
          {' '}
          <AppIcon
            name="fa.plus"
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        <Trans>Load card</Trans>
      </div>
    );
    const sendMoney = (
      <div
        onClick={() =>
          verified
            ? this.props.history.push('/send-money')
            : ToastHelper.show(
                'Your account is not verified yet. Please stay tuned.'
              )
        }
        className="send-load-btn-wrapper"
      >
        {' '}
        <div className="send-load-icon-wrapper">
          <AppIcon
            name="fa.long-arrow-right"
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        <Trans>Send money</Trans>
      </div>
    );

    let cardLock: any = null;
    if (paymentDevice) {
      if (paymentDevice.locked) {
        cardLock = (
          <div
            className="lock-card-wrapper"
            onClick={val => this._changeCardLockStatus(false)}
          >
            <img
              src={require('@pdl/app/assets/img/card-locked-gold.svg')}
              style={{ width: 15, marginRight: 10 }}
            />
            <p>
              <Trans>Unlock Card</Trans>
            </p>
          </div>
        );
      } else {
        cardLock = (
          <div
            className="lock-card-wrapper"
            onClick={val => this._changeCardLockStatus(true)}
          >
            <img
              src={require('@pdl/app/assets/img/card-unlocked-icon.svg')}
              style={{ width: 15, marginRight: 10 }}
            />
            <p>
              <Trans>Lock Card</Trans>
            </p>
          </div>
        );
      }
    }

    return (
      <div className="container">
        <div className="dashboard-header">
          <div className="balance-holder">
            <div className="total-holder">
              <h3>
                <Trans>Available Balance</Trans>
              </h3>
              <h1>
                {NumberHelper.currency(account.balance, account.currency)}
              </h1>
            </div>
            {/* <div className='total-holder'>
                            <h5>SPENT TODAY</h5>
                            <h4>{NumberHelper.currency(profile.spentToday, paymentDevice.currency)}</h4>
                        </div> */}
          </div>
          <div className="header-lock-card-holder">
            {this.props.presentationOnly ? null : (
              <LockCardButton card={paymentDevice} custom />
            )}
          </div>
          <WalletChart />
          <hr style={{ width: '100%', position: 'absolute' }} />
          <div
            className="send-load-btn-holder"
            style={{
              opacity: cardActive ? 1 : 0.2,
              pointerEvents: cardActive ? 'auto' : 'none'
            }}
          >
            {this.props.presentationOnly ? null : loadAccount}
            {this.props.presentationOnly ? null : sendMoney}
          </div>
        </div>
        {/* <ModalDialog show={this.state.loadModal} closeLostStolenModal={this.closeLoadModal.bind(this)}>
                    <div className="transaction-modal">

                        <div className='close-btn'>
                            <AppIcon name={'fa.times'} style={{ fontSize: 24, color: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={() => this.closeLoadModal()} />
                        </div>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        </div>
                        <div style={{ display: 'flex', flex: 0.5, alignItems: 'center', justifyContent: 'center' }}>
                            <AppButton customstyle={{ maxWidth: 450}}>SEND REQUEST</AppButton>
                        </div>
                    </div>
                </ModalDialog> */}
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
  accounts: any;
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default withRouter<IProps, any>(
  connect(mapStateToProps)(DashbaoardHeader)
);
