import React from 'react';
import { AppIcon, SwitchButton, AppButton } from '@pdl/app';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import CardModel from '../models/CardModel';
import AuthService from '@pdl/app/logic/services/AuthService';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import Config from '@pdl/app/config';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

class LockCard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      lostStolenModal: false
    };
  }

  _changeCardLockStatus(status: any) {
    this.closeLostStolenModal();
    let { card } = this.props;
    LoadingHelper.executeAction(
      () => {
        return new CardModel().freezePaymentDevice(card.id, status);
      },
      {
        successMessage: status
          ? i18n.t('Your card is locked')
          : i18n.t('Your card is now unlocked'),
        loadingMessage: status
          ? i18n.t('Locking card...')
          : i18n.t('Unlocking card...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: true,
        renderIcon: (state: string, params: any) => {
          if (state === 'loading') return null;
          return status ? (
            <AppIcon name={'fa.lock'} style={{ fontSize: 150 }} />
          ) : (
            <AppIcon name={'fa.unlock-alt'} style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  openLostStolenModal() {
    this.setState({
      lostStolenModal: true
    });
  }

  closeLostStolenModal() {
    this.setState({
      lostStolenModal: false
    });
  }

  render() {
    let { card } = this.props;
    return (
      <div
        className="row details-holder justify-content-center align-items-center"
        style={{ flexDirection: 'column' }}
      >
        <div className="top-line"></div>

        <div className="col-sm-4">
          <ul className="control-card">
            <li>
              <Trans>{card.locked ? 'Unlock card' : 'Lock card'}</Trans>
              <SwitchButton
                isChecked={!!card.locked}
                onChange={(val: any) => this._changeCardLockStatus(val)}
              />
            </li>
          </ul>
        </div>
        <div>
          <AppButton onClick={() => this.openLostStolenModal()}>
            <Trans>REPORT LOST OR STOLEN</Trans>
          </AppButton>
        </div>

        <ModalDialog
          show={this.state.lostStolenModal}
          closeLostStolenModal={this.closeLostStolenModal.bind(this)}
        >
          <div className="lock-card-modal">
            <div className="close-btn">
              <AppIcon
                name={'fa.times'}
                style={{
                  fontSize: 24,
                  color: 'rgba(0,0,0,0.4)',
                  cursor: 'pointer'
                }}
                onClick={() => this.closeLostStolenModal()}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}
            >
              <h1 style={{ fontSize: 36, color: '#474747' }}>
                Was your card lost or stolen?
              </h1>
              <h2 style={{ fontSize: 18, paddingTop: 20, color: '#474747' }}>
                If so, please <strong>lock your card</strong> as soon as
                possible and contact us on this number
              </h2>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: 150,
                  padding: '30px 0'
                }}
              >
                <span style={{ color: '#474747' }}>
                  {card.locked ? 'Unlock card' : 'Lock card'}
                </span>
                <SwitchButton
                  isChecked={!!card.locked}
                  onChange={(val: any) => this._changeCardLockStatus(val)}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AppIcon
                  name={'fa.phone'}
                  style={{ fontSize: 32, color: '#3FD184' }}
                  onClick={() => this.closeLostStolenModal()}
                />
                <a href={'tel:' + Config.support.phone}>
                  <p style={{ fontSize: 22 }}>
                    <strong>({Config.support.phone})</strong>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

export default LockCard;
