import React from 'react';
import { ModalDialog, AppIcon } from '@pdl/app/components';
import ModalService from '@pdl/app/logic/services/ModalService';
import { connect } from 'react-redux';
import Auth from '@pdl/app/logic/services/AuthService';
import LoadingHelper from '@pdl/app/util/LoadingHelper';

export const NAME = 'THREEDS_MODAL';

class ThreeDSModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      amount: props.amount,
      show: true,
      showAddForm: true,
      digits: ['', '', '', '']
    };
  }

  onClose() {
    ModalService.hideModal(NAME);
  }

  componentDidMount() {
    window.addEventListener('message', e => this.handleIframeTask(e));
  }

  handleIframeTask(e: any) {
    const data = JSON.parse(e.data);

    if (data.success) {
      LoadingHelper.executeAction(
        () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ModalService.hideModal(NAME);
              Auth.refreshAuth();
              resolve(true);
            }, 2000);
          });
        },
        {
          loadingMessage: 'Processing payment...',
          successMessage: 'Payment completed!',
          beforeClose: () => {
            this.props.onSuccess();
          }
        }
      );
    } else {
      LoadingHelper.executeAction(
        () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ModalService.hideModal(NAME);
              Auth.refreshAuth();
              reject(false);
            }, 2000);
          });
        },
        {
          loadingMessage: 'Processing payment...',
          failureMessage: 'Payment failed!',
          beforeClose: () => {
            if (this.props.onFailure) {
              this.props.onFailure();
            }
            ModalService.hideModal(NAME);
          }
        }
      );
    }
  }

  _urlLoaded(iframe) {}

  render() {
    return (
      <ModalDialog show={this.state.show} onClose={() => this.onClose()}>
        <div style={{ backgroundColor: 'white', position: 'relative' }}>
          <div className="close-btn" style={{ textAlign: 'right' }}>
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <iframe
            style={{
              minHeight: 500,
              width: 500,
              maxWidth: '100%',
              border: 'none'
            }}
            src={this.props.redirect}
            onLoad={e => this._urlLoaded(e.target)}
          />
        </div>
      </ModalDialog>
    );
  }
}
const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(ThreeDSModal);
