import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import RoutingService from '@pdl/app/logic/services/RoutingService';

class AppRoute extends React.Component {
  state = {
    component: null
  };

  constructor(props) {
    super(props);
    this.cached = {};
  }

  componentDidMount() {
    const page = this.props.location.pathname;
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;

    // if (currentPage !== nextPage) {
    //   GoogleAnalytics.pageview(nextPage);
    // }
  }

  componentDidUpdate() {
    if (!RoutingService.isScrollPrevented()) {
      // window.scrollTo(0, 0)
    }
  }

  render() {
    // Preven authenticated to access public routes
    if (this.props.unAuthenticatedOnly && this.props.profile) {
      return <Redirect to={this.props.authenticatedRoute} />;
    }

    // Prevent nonauthenticated to access private routes
    if (this.props.authenticatedOnly && !this.props.token) {
      return <Redirect to={this.props.unAuthenticatedRoute} />;
    }

    if (this.props.authenticatedOnly && !this.props.profile) {
      return null;
    }

    let { component: Component, layout: Layout, ...rest } = this.props;

    if (!Component) {
      Component = () => this.props.children;
    }

    if (this.props.load) {
      Component = this.cached[this.props.path]
        ? this.cached[this.props.path]
        : props => <DynamicLoad {...props} load={this.props.load} />;
      this.cached[this.props.path] = Component;
    }

    if (Layout) {
      return (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} {...rest} />
            </Layout>
          )}
        />
      );
    }
    return (
      <Route {...rest} render={props => <Component {...props} {...rest} />} />
    );
  }
}

class DynamicLoad extends React.Component {
  state = {
    component: null
  };

  componentDidMount() {
    if (this.props.load && !this.state.component) {
      this.props.load().then(component => {
        this.setState({
          component: component.default ? component.default : component
        });
      });
    }
    if (!RoutingService.isScrollPrevented()) {
      // window.scrollTo(0, 0)
    }
  }

  render() {
    if (this.state.component) {
      const Component = this.state.component;
      return <Component {...this.props} />;
    }
    return <div style={{ background: 'white', height: 1000 }} />;
  }
}

AppRoute.defaultProps = {
  authenticatedRoute: '/',
  unAuthenticatedRoute: '/login'
};

/**
 * Map state to props
 *
 * @param {Object} state
 */
const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    token: state.auth.token
  };
};

/**
 * Map dispatch to props
 *
 * @param {function} dispatch
 */
const mapDispatchToProps = dispatch => {
  return {
    redirect: token => dispatch(push(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
