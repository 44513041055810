import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

interface BProps {
  isLoading?: boolean;
  customstyle?: any;
  click?: any;
  onClick?: () => void;
}

export class AppButton extends React.Component<BProps> {
  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className="circle-progress" size={16} />;
    }

    return this.props.children;
  }

  render() {
    const { isLoading, ...other } = this.props;
    return (
      <div style={this.props.customstyle}>
        <Button
          variant="contained"
          className="gold-button"
          onClick={this.props.click}
          {...other}
        >
          {this._getButtonContent()}
        </Button>
      </div>
    );
  }
}

export const SelectedButton: React.FC<any> = props => {
  // if (true) {
  //   return <ProsperButton {...props} />
  // } else {
  return <AppButton {...props} />;
  // }
};

export default SelectedButton;
