import React from 'react';
import AppCard from '@pdl/app/components/elements/AppCard';
import DashboardHeader from './components/DasboardHeader';
import LatestTransactions from './components/LatestTransactions';
import LatestNotifications from './components/LatestNotifications';
import i18n from 'i18n';

const Dashboard: React.FC = () => {
  return (
    <div className="appContainer">
      <DashboardHeader />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <AppCard name="shopping-cart" title={i18n.t('Latest Transactions')}>
              <LatestTransactions />
            </AppCard>
          </div>
          <div className="col-lg-6">
            <AppCard name="envelope-o" title={i18n.t('New Notifications')}>
              <LatestNotifications />
            </AppCard>
          </div>
        </div>
        {/* <div className="row" style={{ marginTop: 20 }}>
          <div className="col-lg-6">
            <AppCard name="building-o" title="The nearest ATM" />
          </div>
          <div className="col-lg-6">
            <AppCard name="money" title="Exchange rates">
              <div className="exchange-holder">
                <h1>USD > EUR</h1>
                <p>1 EUR = 1.1736 USD</p>
              </div>
              <div className="exchange-holder">
                <h1>GBP > USD</h1>
                <p>1 USD = 1.1936 GBP</p>
              </div>
            </AppCard>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
