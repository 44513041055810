import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginTriggerAction } from '../AuthActions';
import { FormSubmitWrapper, FormHandler, InputField } from '@pdl/app';
import AuthorizationLoginModel from '../models/AuthorizationLoginModel';
import ScaService from '../../../logic/services/ScaService';
import AuthLayout from '../../sca/otp/AuthLayout';

class AuthorizationLogin extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new AuthorizationLoginModel({
        email: '',
        password: '',
        scaTrigger: 'consent-' + this.props.location.query.consent,
        actionTitle: 'Authorize login',
        actionText: 'Login authorization required for web portal'
      }),
      {
        redirect: false
      }
    );

    this.formHandler.on('submitSuccess', data => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        email: '',
        password: '',
        remember: false,
        showInitialModal: false,
        isVisa: false
      }
    });
  }

  onSubmitSuccess(data) {
    if (data.success) {
      let { nextState } = data.data || {};
      if (nextState) {
        const { hash = '', token = '', request = '', retries = '' } =
          nextState.payload || {};
        this.props.history.push('/sca-process', {
          scaActionHash: ScaService.registerAction(() =>
            this.formHandler.handleSubmit()
          ),
          fromLogin: true,
          nextState: nextState.name,
          payload: nextState.payload,
          hash: hash,
          token: token,
          request: request,
          retries: retries || 3,
          email: this.state.record.email,
          password: this.state.record.password
        });
      } else {
        if (data.success && data.data.token) {
          this.props.userLoggedIn(data.data.token, data.data.account, true);
          this.props.history.push('/user-consent', {
            consent: this.props.location.query.consent
          });
        }
      }
    }
  }

  _getModalMessage(data) {
    if (!data.success) {
      if (data.errors && data.errors.length > 0) {
        return data.errors[0].message;
      }
      if (data.message) {
        return data.message;
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/"></Redirect>;
    }

    let formHandler = this.formHandler;
    return (
      <AuthLayout>
        <div>
          <div className="container">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-md login-holder">
                <div className="login-box" id="log-in">
                  <div style={{ marginTop: 10 }}>
                    <h3 style={{ color: '#424242', marginBottom: 20 }}>
                      Please login to continue with authorisation
                    </h3>
                    <FormSubmitWrapper
                      formHandler={formHandler}
                      buttonClass="gold-button"
                      to="/"
                      buttonText={'Log In'}
                      getModalMessage={data => this._getModalMessage(data)}
                    >
                      <div className="login-input-wrapper">
                        <InputField
                          className={'input-group'}
                          materialProps={{
                            fullWidth: true
                          }}
                          autoComplete={this.state.record.email}
                          label=""
                          name="email"
                          placeholder={'Email'}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={this.state.record.email}
                          handler={formHandler}
                        />
                        {/* <img
                        onClick={() =>
                          ModalService.showModal('CUSTOMER_ID_HELP')
                        }
                        src={require('WebsiteApp/assets/img/info-icon.svg')}
                        alt="info icon"
                        className="input-infobutton"
                        style={{ marginBottom: 5 }}
                      /> */}
                      </div>
                      <div className="login-input-wrapper">
                        <InputField
                          className={'input-group'}
                          materialProps={{
                            fullWidth: true
                          }}
                          autoComplete={this.state.record.password}
                          label=""
                          type="password"
                          name="password"
                          placeholder={'Enter your password'}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={this.state.record.password}
                          handler={formHandler}
                        />
                      </div>
                    </FormSubmitWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userLoggedIn: (token, account, isTemporary = false) => {
      return dispatch(loginTriggerAction(token, account, isTemporary));
    }
  };
};

export default connect(null, mapDispatchToProps)(AuthorizationLogin);
