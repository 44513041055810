import React, { Component } from 'react';
import { AppList } from '@pdl/app';
import AuthService from '@pdl/app/logic/services/AuthService';
import { connect } from 'react-redux';
import NotificationModel from './models/NotificationModel';
import NotificationListItem from './components/NotificationListItem';

class NotificationsPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      notificationsItems: []
    };

    setTimeout(() => {
      AuthService.refreshAuth();
    }, 4000);
  }

  _renderNotificationItem(i: any, index: string) {
    return (
      <NotificationListItem notification={i} key={`notification${index}`} />
    );
  }

  componentDidMount() {
    new NotificationModel().seenNotifications();
  }

  _getNotificationsHeader() {
    return null;
    
    return (
      <div className="notifications-header">
        <button type="button" className="select-all-button">
          <span>Select All</span>
        </button>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="dropdown notifications-dropdown">
            <button
              type="button"
              className="dropdown-toggle notifications-dropdown-button"
              data-toggle="dropdown"
            >
              <span>More</span>
              {/* <i className="fa fa-caret-down"></i> */}
            </button>
            <ul className="dropdown-menu" id="moreDropdown">
              <li>Archive</li>
              <li>Mark as read</li>
              <li>Mark as unread</li>
            </ul>
          </div>
          <div className="dropdown notifications-dropdown">
            <button
              type="button"
              className="dropdown-toggle notifications-dropdown-button"
              data-toggle="dropdown"
            >
              <span>Type</span>
              {/* <i className="fa fa-caret-down"></i> */}
            </button>
            <ul className="dropdown-menu" id="moreDropdown">
              {/* <span className="arrow-up"></span>
                    <span className="cover-arrow"></span> */}
              <li>
                System message{' '}
                <img
                  src={require('../../assets/Tick.svg')}
                  className="check-icon"
                  alt=""
                />
              </li>
              <li>
                Promotion and offers{' '}
                <img
                  src={require('../../assets/Tick.svg')}
                  className="check-icon"
                  alt=""
                />
              </li>
              <li>
                Incoming transactions{' '}
                <img
                  src={require('../../assets/Tick.svg')}
                  className="check-icon"
                  alt=""
                />
              </li>
              <li>
                Outgoing transactions{' '}
                <img
                  src={require('../../assets/Tick.svg')}
                  className="check-icon"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // let unreadNotifications = null;

    // if (this.props.profile && this.props.profile.unreadNotifications) {
    //   unreadNotifications = (
    //     <div className="notificationNumber">
    //       <span>{this.props.profile.unreadNotifications}</span>
    //     </div>
    //   );
    // }

    return (
      <div className="container">
        <div style={{ paddingTop: 100 }}>
          <div
            className="profile-container"
            style={{ height: '70vh', overflow: 'auto', padding: 0 }}
          >
            <div className="">
              <div className="notifications-col-12">
                <div className="notifications-body">
                  <AppList
                    model={NotificationModel}
                    renderWrapper={(items: React.ReactNode) => (
                      <ul style={{ padding: 0, marginBottom: 0 }}>{items}</ul>
                    )}
                    renderItem={(item: any, index: any) =>
                      this._renderNotificationItem(item, index)
                    }
                    renderNoItems={() => (
                      <div className="col-sm-12 well text-center">
                        <div style={{ margin: '20px' }}>
                          <em>No new notifications</em>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(NotificationsPage);
