import { fork, all, put, takeEvery } from 'redux-saga/effects';
import AuthSagas from '../../modules/auth/AuthSagas';
import { APP_MOUNTED } from './AppActions';
import { MEMBER_TOKEN_LOADED } from '../../modules/auth/AuthActions';
import ConfigModel from '../model/ConfigModel';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* appMounted(action) {
  try {
    // TODO: Fix this, load is not static function
    yield ConfigModel.load();

    let token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
    }

  } catch (e) {
  }
}

export function* watchAppMounted() {
  yield takeEvery(APP_MOUNTED, appMounted);
}

export default function* allSagas() {
  yield fork(watchAppMounted);
  yield fork(AuthSagas)
}
