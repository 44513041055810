import React from 'react';
import { connect } from 'react-redux';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppButton
} from '@pdl/app';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { Trans } from 'react-i18next';
import AccountService from '../../account/AccountService';
import SendMoneyModal from '../models/SendMoneyModel';
import i18n from '../../../../../i18n';
import SendMoneyService from '../SendMoneyService';

class CustomerReference extends React.Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new SendMoneyModal({
        customerReference: ''
      }),
      {
        user: {}
      }
    );
    this.formHandler.on('submitSuccess', (data: any) => {
      if (!data.success && data.message && data.message.length > 0) {
        ToastHelper.show(data.message);
      }
    });
    this.formHandler.on('submitError', (data: any) => {
      if (data.message && data.message.trim().length) {
        ToastHelper.show(data.message);
      }
    });
  }

  componentDidMount() {
    const mainAccount = AccountService.getMainAccount(this.props.accounts);

    SendMoneyService.initTransfer(mainAccount);
  }

  onSubmitSuccess(data) {
    if (data.success) {
      this.setState(
        prevState => ({
          ...prevState,
          record: {
            ...prevState.record,
            customerReference: ''
          }
          // user: data.data
        }),
        () => {
          this.props.nextStep();
          this.props.getUser(data.data);
        }
      );
    }
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    const { formHandler } = this;

    return (
      <>
        <h4>
          <Trans>Please enter Tillo customer reference</Trans>
        </h4>
        <FormSubmitWrapper
          buttonClass="gold-button"
          formHandler={formHandler}
          buttonText={i18n.t('Confirm')}
          afterSubmit={() => {
            return (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.history.goBack()}
                  style={{ color: '#4f3bd0', padding: 10 }}
                >
                  Back
                </a>
              </div>
            );
          }}
        >
          <div className="mb-5">
            <InputField
              className="input-group customer-reference-input-wrapper"
              materialProps={{
                fullWidth: true
              }}
              autoComplete={this.state.record.customerReference}
              label={i18n.t('Customer Reference')}
              type="number"
              name="customerReference"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.record.refNumber}
              handler={formHandler}
            />
          </div>
        </FormSubmitWrapper>
      </>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(CustomerReference);
