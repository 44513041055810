import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { AppButton } from '@pdl/app/components';
import CardModel from '../models/CardModel';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

class ShowPin extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      pin: '',
      arrayPin: '',
      text: '',
      error: false,
      loading: false
    };
  }

  componentDidMount() {
    this.loadPin();
  }

  async loadPin() {
    this.setState({
      error: false,
      loading: true
    });
    const { card } = this.props;

    let pin = [];
    try {
      pin = await new CardModel().fetchPin(card);

      this.setState({
        pin,
        arrayPin: Array.from(pin) as any,
        text: i18n.t('Hide'),
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: true
      });
    }
  }

  hidePin() {
    this.setState({
      pin: '',
      arrayPin: Array.from('****') as any,
      text: i18n.t('Unhide')
    });
  }

  unhidePin() {
    this.loadPin();
  }

  render() {
    const { pin, arrayPin, text } = this.state;

    let innerElement = (
      <div className="col-md-8">
        <h3>
          <Trans>Your Card PIN</Trans>
        </h3>

        <div className="col-md-12 pin-wrapper">
          <div className="pin-number-holder">
            <span>{arrayPin[0]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[1]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[2]}</span>
          </div>
          <div className="pin-number-holder">
            <span>{arrayPin[3]}</span>
          </div>
          {pin !== '' && pin ? (
            <h4 onClick={() => this.hidePin()}>{text}</h4>
          ) : (
            <h4 onClick={() => this.unhidePin()}>{text}</h4>
          )}
        </div>

        <p>
          <Trans>You can change your PIN at any ATM</Trans>
        </p>
      </div>
    );

    if (this.state.loading) {
      innerElement = (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress className="loading-circle-progress" />
        </div>
      );
    }

    if (this.state.error) {
      innerElement = (
        <div style={{ textAlign: 'center' }}>
          <p>
            <Trans>An error ocurred, please try later</Trans>
          </p>
          <AppButton
            style={{ marginTop: 20 }}
            className="gold-button"
            onClick={() => this.loadPin()}
          >
            <Trans>Try again</Trans>
          </AppButton>
        </div>
      );
    }

    return (
      <div
        className="row details-holder justify-content-center align-items-center"
        style={{ flexDirection: 'column' }}
      >
        <div className="top-line" />
        {innerElement}
      </div>
    );
  }
}

export default ShowPin;
