/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import ModalService from '@pdl/app/logic/services/ModalService';
import NumberHelper from '@pdl/app/util/NumberHelper';
import DateHelper from '@pdl/app/util/DateHelper';
import { AppIcon, AppButton } from '@pdl/app';
import i18n from 'i18n';
import { Trans } from 'react-i18next';

export default class TransactionInfoModal extends React.PureComponent {
  render() {
    const { transaction } = this.props;
    const isFX =
      transaction.originalAmountCurrency &&
      transaction.originalAmountCurrency !== transaction.amountCurrency;
    const amount =
      transaction.amount === 0
        ? NumberHelper.currency(transaction.fee, transaction.amountCurrency)
        : NumberHelper.currency(transaction.amount, transaction.amountCurrency);

    const dataToShow = [
      {
        label: i18n.t('Type'),
        value: i18n.t(transaction.typeReadable)
      },
      {
        label: i18n.t('Status'),
        value: i18n.t(transaction.statusReadable)
      },
      {
        label: i18n.t('Transaction Date'),
        value: DateHelper.date(
          transaction.postDate || transaction.transactionDate
        )
      },
      {
        label: i18n.t('Settlement Date'),
        value: DateHelper.date(transaction.transactionDate)
      }
    ];

    let fee = parseFloat(transaction.fee);
    if (parseFloat(transaction.fee)) {
      if (isFX && transaction.status === 'settled') {
        fee -= parseFloat(transaction.fxPadding);
      }
    }

    if (transaction.amount === 0 && transaction.fee > 0) {
      fee = null;
    }

    if (isFX) {
      const rate = Math.abs(transaction.originalAmount / transaction.amount);
      dataToShow.push({
        label: i18n.t('Spent'),
        value: NumberHelper.currency(
          transaction.originalAmount,
          transaction.originalAmountCurrency
        )
      });
      dataToShow.push({
        label: i18n.t('FX rate'),
        value: `${NumberHelper.currency(
          1,
          transaction.amountCurrency
        )} = ${NumberHelper.currency(rate, transaction.originalAmountCurrency)}`
      });
      if (fee) {
        dataToShow.push({
          label: i18n.t('Fee'),
          value: NumberHelper.currency(
            parseFloat(transaction.fee) - parseFloat(transaction.fxPadding),
            transaction.amountCurrency
          )
        });
      }
    } else if (fee) {
      dataToShow.push({
        label: i18n.t('Fee'),
        value: NumberHelper.currency(
          parseFloat(transaction.fee),
          transaction.amountCurrency
        )
      });
    }

    dataToShow.push({
      label: i18n.t('Running balance'),
      value: NumberHelper.currency(
        transaction.runningBalance,
        transaction.amountCurrency
      )
    });

    const fullDate = DateHelper.fullDate(transaction.transactionDate);

    return (
      <ModalDialog id="TRANSACTION_INFO" show>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <h4>
              <Trans>{transaction.note}</Trans>
            </h4>
            <span className="icon-holder">
              <i
                className="fa fa-info"
                aria-hidden
                style={{ color: 'white', fontSize: 31 }}
              />
            </span>
            <div>{fullDate}</div>
            <p className="tx-amount">{amount}</p>
            <div className="transaction-details">
              {dataToShow.map((x, index) => {
                return (
                  <div className="row" key={`transaction${index}`}>
                    <div className="col-xs-6 detail-label">{x.label}:</div>
                    <div className="col-xs-6 detail-value">{x.value}</div>
                  </div>
                );
              })}
            </div>
            {transaction.fxFee ? (
              <a
                // eslint-disable-next-line no-script-url
                href="javascript:void(0)"
                style={{
                  marginBottom: 20,
                  textDecoration: 'underline',
                  color: '#4f3bd0'
                }}
                onClick={() =>
                  ModalService.showModal('FX_INFO', { transaction })
                }
              >
                <Trans>Transaction Fees</Trans>
              </a>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flex: 0.5,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {/* <AppButton customstyle={{ maxWidth: 450, maxHeight: 60 }}>
              YOU DON’T RECOGNIZE THIS TRANSACTION?
            </AppButton> */}
          </div>
        </div>
      </ModalDialog>
    );
  }
}
