import React from 'react';

export const BottomWaveForCard: React.FC<any> = () => {
  return (
    <div className="svg-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="40%">
            <stop
              offset="0%"
              style={{ stopColor: '#0F5EF6', stopOpacity: 0.4 }}
            />
            <stop
              offset="30%"
              style={{ stopColor: '#0F5EF6', stopOpacity: 0.4 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: '#11BEFE', stopOpacity: 0.4 }}
            />
          </linearGradient>
        </defs>
        <path
          fill="url(#gradient)"
          fillOpacity="1"
          d="M0,96L60,85.3C120,75,240,53,360,58.7C480,64,600,96,720,133.3C840,171,960,213,1080,224C1200,235,1320,213,1380,202.7L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </svg>
    </div>
  );
};

export default BottomWaveForCard;
