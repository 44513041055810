
const actions = {};

export default class ScaService
{
  static registerAction(action) {
    const hash = this.generateHash();
    actions[hash] = action;
    return hash;
  }

  static getAction(hash) {
    return actions[hash];
  }

  static generateHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

}
