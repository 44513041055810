import React, { Component } from 'react';
// import SignUpStepOneForm from "../auth/pages/sign-up/forms/SignUpStepOneForm";
// import OTPRegisterPage from "./otp/OTPRegisterPage";
import OTPConfirmationPage from './otp/OTPConfirmationPage';
// import OTPCallCentrePage from "./otp/OTPCallCentrePage";
// import SignUpPasswordForm from "../auth/pages/sign-up/forms/SignUpPasswordForm";
// import UserConsent from "./consent/UserConsent";
import ScaService from '../../logic/services/ScaService';
import WaitForSca from './consent/WaitForSca';

class SCAWrapper extends Component {
  constructor(props) {
    super(props);

    const navigationProps =
      props.location && props.location.state ? props.location.state : {};
    this.state = {
      customerId: '',
      birthDate: '',
      request: '', // email || phone
      requestValue: '',
      password: '',
      token: '',
      resendPasscodeToken: '',
      hash: '',
      currentScreen: '',
      nextState: '',
      fromLogin: false, // Resend passcode, differs for registration and login
      timer: 60,
      message: '',
      ...navigationProps
    };
  }

  updateState = state => {
    this.setState(prevState => ({
      ...prevState,
      ...state
    }));
  };

  _onSuccess(data = null) {
    let action = ScaService.getAction(this.state.scaActionHash);
    if (action) {
      action(data);
    } else {
      this.setState({
        nextState: data.name
      });
    }
  }

  render() {
    switch (this.state.nextState) {
      // case "SignUpPage":
      //   return (
      //     <SignUpStepOneForm
      //       type={this.props.type}
      //       onSuccess={data => this.props.onSuccess(data)}
      //       onFailure={data => this.onFailure(data)}
      //       setParentState={state => this.updateState(state)}
      //     />
      //   );
      // case "ASK_FOR_DATA":
      //   return (
      //     <OTPRegisterPage
      //       type={this.props.type}
      //       setParentState={this.updateState}
      //       token={this.state.token}
      //       hash={this.state.hash}
      //       request={this.state.request}
      //     />
      //   );
      case 'ASK_FOR_OTP':
        return (
          <OTPConfirmationPage
            type={this.props.type}
            setParentState={this.updateState}
            token={this.state.token}
            request={this.state.request}
            resendPasscodeToken={
              this.state.resendPasscodeToken || this.state.token
            }
            hash={this.state.hash}
            retries={this.state.retries || 3}
            timer={this.state.timer}
            requestValue={this.state.requestValue}
            fromLogin={this.state.fromLogin}
            customerId={this.state.customerId}
            password={this.state.password}
            onSuccess={data => this._onSuccess(data)}
            message={this.state.message}
          />
        );
      // case "SHOW_CONSENT":
      //   return <UserConsent />;
      case 'WAIT_FOR_SCA':
        return (
          <WaitForSca
            type={this.props.type}
            payload={this.state.payload}
            onSuccess={data => this._onSuccess(data)}
          />
        );
      // case "SUCCESSFUL_SCA":
      //   return (
      //     <SignUpPasswordForm
      //       type={this.props.type}
      //       data={{
      //         email:
      //           this.state.request === "email" ? this.state.requestValue : null,
      //         mobile:
      //           this.state.request === "phone" ? this.state.requestValue : null,
      //         user: {
      //           customerId: this.state.customerId
      //         },
      //         birthDate: this.state.birthDate
      //       }}
      //     />
      //   );
      // case "FAILED_SCA":
      // case "CALL_CENTER":
      //   return <OTPCallCentrePage />;
      default:
        return (
          <OTPConfirmationPage
            type={this.props.type}
            setParentState={this.updateState}
            token={this.state.token}
            request={this.state.request}
            resendPasscodeToken={
              this.state.resendPasscodeToken || this.state.token
            }
            hash={this.state.hash}
            retries={this.state.retries || 3}
            timer={this.state.timer}
            requestValue={this.state.requestValue}
            fromLogin={this.state.fromLogin}
            customerId={this.state.customerId}
            password={this.state.password}
            onSuccess={data => this._onSuccess(data)}
          />
          // <SignUpStepOneForm
          //   type={this.props.type}
          //   onSuccess={data => this.props.onSuccess(data)}
          //   onFailure={data => this.onFailure(data)}
          //   setParentState={state => this.updateState(state)}
          // />
        );
    }
  }
}

export default SCAWrapper;
