import React from 'react';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  createStyles,
  withStyles,
  Theme,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(255, 255, 255, 1)' }
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'transparent!important'
        },
        '&:after': {
          borderBottom: 'transparent!important'
        }
      },
      root: {
        // Name of the rule
        color: '#c3251f', // Some CSS,
        backgroundColor: 'white'
      }
    }
  }
});

const styles = (theme: Theme) =>
  createStyles({
    // root: {
    //   flexGrow: 1,
    //   height: 240,
    //   color: '#c3251f',
    //   backgroundColor: 'white'
    // },
    // input: {
    //   display: 'flex',
    //   padding: '2px 0',
    //   color: '#c3251f',
    //   fontWeight: 300,
    //   backgroundColor: 'white'
    // },
    // valueContainer: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   flex: 1,
    //   alignItems: 'center',
    //   color: '#c3251f'
    // },
    // chip: {
    //   margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
    // },
    // chipFocused: {
    //   backgroundColor: emphasize(
    //     theme.palette.type === 'light'
    //       ? theme.palette.grey[300]
    //       : theme.palette.grey[700],
    //     0.08
    //   )
    // },
    // noOptionsMessage: {
    //   padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
    // },
    // singleValue: {
    //   fontSize: 16,
    //   color: '#c3251f',
    //   fontWeight: 300
    // },
    // placeholder: {
    //   position: 'absolute',
    //   left: 10,
    //   fontSize: 16,
    //   color: '#c3251f',
    //   display: 'none'
    // },
    // paper: {
    //   position: 'absolute',
    //   zIndex: 105,
    //   marginTop: theme.spacing(1),
    //   left: 0,
    //   right: 0,
    //   cursor: 'pointer'
    // }
  });

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Select
        native
        fullWidth
        InputProps={{
          inputComponent,
          inputprops: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    </MuiThemeProvider>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 300 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class AppSelect extends React.Component<any, any> {
  model: any = null;

  state: any;

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      items: []
    };
  }

  componentDidMount() {
    this._loadItems();
  }

  static getDerivedStateFromProps(props, state) {
    const stateChange: any = {};
    if (props.value) {
      const selectedOption = state.items.find(x => x.id === props.value);
      stateChange.selectedOption = selectedOption;
    }
    if (props.value === null) {
      stateChange.selectedOption = null;
    }
    if (props.options) {
      stateChange.items = props.options;
    }
    return stateChange;
  }

  getValidationError() {
    const messages: any[] = [];

    this.props.handler
      .getErrors()
      .filter(x => x.field === this.props.name)
      .forEach(error => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${this.props.name}-error`}>
        {messages.map(x => (
          <div>{x}</div>
        ))}
      </div>
    );
  }

  handleChange(option) {
    this.setState(
      {
        selectedOption: option
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(this.props.name, option.id);
        }
      }
    );
  }

  /**
   * Load items
   */
  async _loadItems() {
    if (!this.props.model) {
      if (this.props.options) {
        let selectedOption = null;
        if (this.props.value) {
          selectedOption = this.props.options.find(
            x => x.id === this.props.value
          );
        }
        this.setState({
          items: this.props.options,
          selectedOption
        });
        return;
      }
    }

    // Show loader
    this._loadingOn();

    try {
      const results = await this._getModel().fetchFlat();

      results.forEach(result => {
        result.id = this._getOptionValue(result);
        result.name = this._getOptionName(result);
      });

      let selectedOption = null;
      if (this.props.value) {
        selectedOption = results.find(x => x.id === this.props.value);
      }

      this.setState({
        items: results,
        selectedOption
      });
    } catch (e) {}
    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false
    });
  }

  _getOptionValue(option) {
    if (this.props.extractValue) {
      return this.props.extractValue(option);
    }
    return option.id;
  }

  _getOptionName(option) {
    if (this.props.extractName) {
      return this.props.extractName(option);
    }
    return option.name;
  }

  render() {
    const validationError = this.getValidationError();

    return (
      <div
        className={this.props.className}
        style={this.props.style}
        id={validationError ? 'outlined-error' : ''}
      >
        <Autocomplete
          disableClearable
          inputprops={this.props.InputProps}
          components={components}
          // value={this.state.selectedOption}
          value={this.state.selectedOption ? this.state.selectedOption : ''}
          // defaultValue={this.state.selectedOption}
          // defaultValue={
          //   this.state.selectedOption ? this.state.selectedOption : ''
          // }
          // inputValue={this.state.selectedOption ? this.state.selectedOption.name : ''}
          onChange={(e, option) => this.handleChange(option)}
          placeholder={this.props.placeholder}
          options={this.state.items}
          getOptionValue={option => option.id}
          getOptionLabel={(option: any) => option && option.name}
          onInputChange={(event, option, reason) => {
            if (reason === 'input') {
              this.setState({
                selectedOption: option
              });
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={this.props.label}
              variant="outlined"
              autoComplete="off"
              error={!!validationError}
              // value={this.state.selectedOption ? this.state.selectedOption.name : ''}
            />
          )}
          {...this.props.materialProps}
        />
        {validationError}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppSelect);
