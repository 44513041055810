import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppButton } from '@pdl/app';
import { Trans } from 'react-i18next';
import Api from '@pdl/app/logic/api/Api';
import Auth from '@pdl/app/logic/services/AuthService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import UploadFields from './UploadFields';

export default class UploadPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: {},
      loading: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.files != this.state.files) {
      // return false;
    }
    return true;
  }

  _filesChanged(files, type) {
    const stateFiles = { ...this.state.files };

    stateFiles[type] = files.length ? files[0] : null;

    this.setState({
      files: stateFiles
    });
  }

  async _submitDocuments() {
    const { files } = this.state;

    if (!files.proof_of_id || !files.proof_od_address) {
      ToastHelper.show('Please upload both documents!');
      return;
    }

    const documents = [];

    ['proof_of_id', 'proof_od_address'].forEach(key => {
      documents.push({
        name: files[key].name,
        id: files[key].fileId,
        type: key
      });
    });

    if (documents.filter(x => !!x.id).length < 2) {
      ToastHelper.show('Please wait for the upload to finish!');
      return;
    }

    await this._loadingOn();
    try {
      const result = await Api.call('pdl/kyc/submitDocuments', {
        documents
      });

      if (!result.success) {
        throw new Error(result.message);
      }

      Auth.refreshAuth();
    } catch (e) {
      alert(e.message);
      setTimeout(() => {
        this._loadingOff();
      }, 2000);
    }
  }

  _loadingOn() {
    this.setState({
      loading: true
    });
  }

  _loadingOff() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <div>
        <div className="verification-image-signup" />
        <div className="signUp-wrapper create-account">
          <Link
            to="/login"
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontSize: 14,
              textAlign: 'center'
            }}
          >
            <Trans>Already have an account? Sign in</Trans>
          </Link>
        </div>
        <div className="container login-container create-account-container">
          <img
            alt="Tillo"
            src={require('@pdl/app/assets/variants/tillo/images/logo_blue.png')}
            className="login-logo"
            style={{ maxWidth: 200 }}
          />
          <div className="row">
            <div className="col-md login-holder">
              <h4
                className="login-title "
                style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
              >
                Create your account
              </h4>
              <div className="">
                <p style={{ marginBottom: 20, fontSize: 18 }}>
                  Upload your ID and proof of address documents
                </p>
                <UploadFields
                  onChange={(files, type) => this._filesChanged(files, type)}
                />
                {/* <AppCheckboxInput
                  name="agreedToPP"
                  id="squaredTwo"
                  htmlFor="squaredTwo"
                  labelText="I agree to the processing of my personal information in accordance with the Privacy Policy"
                  // value={this.state.checkbox1}
                  handler={this.formHandler}
                  onClick={() => this.handleCheckbox1()}
                />
                <AppCheckboxInput
                  name="agreedToPP"
                  id="squaredTwo3"
                  htmlFor="squaredTwo3"
                  labelText="I understand and accept Tillo Terms and Conditions"
                  // value={this.state.checkbox2}
                  handler={this.formHandler}
                  onClick={() => this.handleCheckbox1()}
                />
                <AppCheckboxInput
                  name="agreedToPP"
                  id="squaredTwo4"
                  htmlFor="squaredTwo4"
                  labelText="GDPR Compliance text (to be provided)"
                  // value={this.state.checkbox2}
                  handler={this.formHandler}
                  onClick={() => this.handleCheckbox1()}
                /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 30,
                  marginBottom: 30
                }}
              >
                <AppButton
                  isLoading={this.state.loading}
                  onClick={() => this._submitDocuments()}
                  className="gold-button"
                  style={{ maxWidth: 400, width: '100%' }}
                >
                  next
                </AppButton>
              </div>
              <div className="signUp-wrapper create-account bottom">
                <Link
                  to="/login"
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                    fontSize: 14,
                    textAlign: 'center'
                  }}
                >
                  <Trans>Already have an account? Sign in</Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
