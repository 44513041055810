import i18n from '../../../../i18n';
import { LANGUAGE_CHANGE } from './LocalizationActions';

const initialState = {
  locale: i18n.language || 'en',
  dictionary: {},
  translations: {},
  languages: [
    {
      id: 1,
      name: 'English',
      locale: 'en',
      ordering: 1,
      isActive: true
    },
    {
      id: 2,
      name: 'German',
      locale: 'de',
      ordering: 1,
      isActive: false
    },
    {
      id: 3,
      name: 'Swedish',
      locale: 'swe',
      ordering: 1,
      isActive: false
    }
  ]
};

const localizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGE:
      return { ...state, locale: action.locale };
    // case CLEAR_REDUX_STORAGE:
    //   return initialState;
    default:
      return state;
  }
};

export default localizationReducer;
