import React from 'react';
import { AppList } from '@pdl/app';
import TransactionModel from '@pdl/app/modules/statements/models/TransactionModel';
import TransactionItem from '../../statements/components/TransactionItem';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export default class LatestTransactions extends React.Component {
  _renderTransactionItem(item: any, index: string | number | undefined) {
    return <TransactionItem key={index} transaction={item} type={'all'} />;
  }

  render() {
    return (
      <div style={{ position: 'relative', minHeight: 445, maxHeight: 450 }}>
        <ul
          style={{ padding: 0, margin: 0, maxHeight: 390, overflow: 'hidden' }}
        >
          <AppList
            model={TransactionModel}
            params={{
              limit: 5
            }}
            renderWrapper={(items: React.ReactNode) => (
              <ul style={{ padding: 0 }}>{items}</ul>
            )}
            renderItem={(item: any, index: string | number | undefined) =>
              this._renderTransactionItem(item, index)
            }
            renderNoItems={() => (
              <div className="col-sm-12 well text-center">
                <div style={{ margin: '20px', color: 'white' }}>
                  <em>
                    <Trans>
                      Once you start using your card, you'll see transactions
                      here.
                    </Trans>
                  </em>
                </div>
              </div>
            )}
          />
        </ul>

        <Link to="/statements" className="go-to-inbox">
          <Trans>View all</Trans>
        </Link>
      </div>
    );
  }
}
