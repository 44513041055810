import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import moment from 'moment';
// import AppValidationErrors from '../AppValidationErrors';
// import AppDatePickerField from './AppDatePickerField';

interface AppDatepickerPropsType {
  datePickerProps: any;
  [name: string]: any;
}

export const AppDatePicker: React.FC<any> = (props: AppDatepickerPropsType) => {
  const [errMess, setErrMess] = React.useState<any>(null);

  const dateChanged = async date => {
    if (props.handler) {
      props.handler.handleInputChange(
        props.name,
        moment(date).format('YYYY-MM-DD')
      );
    }
  };

  function getValidationError() {
    const messages: any[] = [];

    props.handler
      .getErrors()
      .filter(x => x.field === props.name)
      .forEach(error => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${props.name}-error`}>
        {messages.map(x => (
          <div>{x}</div>
        ))}
      </div>
    );
  }

  // const error = (
  //   <AppValidationErrors name={props.name} handler={props.handler} />
  // );

  const validationError = getValidationError();

  return (
    <div
      className={props.className}
      style={props.style}
      id={validationError || errMess ? 'outlined-error' : ''}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          style={{
            width: '100%',
            margin: 0
          }}
          helperText=""
          onError={error => {
            if (error) {
              setErrMess(error);
            } else {
              setErrMess(null);
            }
          }}
          error={!!validationError || errMess}
          format="dd/MM/yyyy"
          margin="normal"
          label={props.label}
          value={props.value}
          onChange={date => dateChanged(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          {...props.datePickerProps}
        />
      </MuiPickersUtilsProvider>
      {validationError}
    </div>
  );
};

export default AppDatePicker;
