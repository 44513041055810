import React from 'react';
import { withRouter } from 'react-router';
import Api from '../../../logic/api/Api';
import AuthLayout from '../otp/AuthLayout';
import Config from '@pdl/app/config';

class WaitForSca extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'in_progress'
    };
  }

  componentDidMount() {
    this._triggerPooling();
  }

  componentWillUnmount() {
    if (this.poolTimeout) {
      clearTimeout(this.poolTimeout);
    }
  }

  async _triggerPooling() {
    let response = await Api.call('sca/check', {
      scaHash: this.props.payload.scaHash
    });

    if (response.data.status === 'confirmed') {
      this.setState({
        status: response.data.status
      });
      return this.props.onSuccess(response.data);
    }

    this.setState({
      status: response.data.status
    });

    if (response.data.status === 'in_progress') {
      this.poolTimeout = setTimeout(() => {
        this._triggerPooling();
      }, 1500);
    }
  }

  _getInProgressElement() {
    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
          Authorise this action on your registered device.
        </h3>
        <p style={{ textAlign: 'left' }}>
          You should receive a push notification to your registered mobile
          device to authorise this action. If you do not receive a push
          notification please go to the “Authorisation codes” section to verify.{' '}
        </p>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <p>Authorisation code: </p>
          <p style={{ fontWeight: 'bold', fontSize: 30, margin: '10px 0' }}>
            {this.props.payload.authorizationKey}
          </p>
          <p style={{ fontSize: 14 }}>
            Please note: You should only approve the authorisation code if you
            see the same numbers on your mobile device.
          </p>
        </div>
        <p style={{ textAlign: 'left' }}>
          If you are having trouble authorising this action, please call our
          friendly customer support team on
          <a href={`tel:${Config.support.phone}`}> {Config.support.phone}*</a>
        </p>
        <p style={{ textAlign: 'left' }}>
          *Calls are charged at your standard network rate, calls from mobiles
          may be higher.{' '}
        </p>
        <button
          className="gold-button"
          onClick={() => this.props.history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth: 200,
            marginTop: 30
          }}
        >
          Back
        </button>
      </React.Fragment>
    );
  }

  _getConfirmedElement() {
    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
          Authorisation attempt approved.
        </h3>
        <p style={{ textAlign: 'center' }}>please wait... </p>
      </React.Fragment>
    );
  }

  _getDeniedElement() {
    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
          Authorisation attempt has been denied!
        </h3>
        <p style={{ textAlign: 'left' }}>
          If you are having trouble authorising this action, please call our
          friendly customer support team on
          <a href={`tel:${Config.support.phone}`}> {Config.support.phone}*</a>
        </p>
        <p style={{ textAlign: 'left' }}>
          *Calls are charged at your standard network rate, calls from mobiles
          may be higher.{' '}
        </p>
        <button
          className="gold-button"
          onClick={() => this.props.history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth: 200,
            marginTop: 30
          }}
        >
          Back
        </button>
      </React.Fragment>
    );
  }

  _getExpiredElement() {
    return (
      <React.Fragment>
        <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
          Authorisation attempt has expired!
        </h3>
        <p style={{ textAlign: 'left' }}>
          If you are having trouble authorising this action, please call our
          friendly customer support team on
          <a href={`tel:${Config.support.phone}`}> {Config.support.phone}*</a>
        </p>
        <p style={{ textAlign: 'left' }}>
          *Calls are charged at your standard network rate, calls from mobiles
          may be higher.{' '}
        </p>
        <button
          className="gold-button"
          onClick={() => this.props.history.goBack()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth: 200,
            marginTop: 30
          }}
        >
          Back
        </button>
      </React.Fragment>
    );
  }

  render() {
    let element = null;

    switch (this.state.status) {
      case 'confirmed':
        element = this._getConfirmedElement();
        break;

      case 'denied':
        element = this._getDeniedElement();
        break;

      case 'expired':
        element = this._getExpiredElement();
        break;

      default:
        element = this._getInProgressElement();
    }

    return (
      <AuthLayout>
        <div>
          <div style={{ width: '100%' }} className="visa-onboarding">
            <div className="content-wrapper no-margin">{element}</div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

export default withRouter(WaitForSca);
