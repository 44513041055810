import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';

export default class ConsentModel extends BaseModel {
  getListUrl() {
    return 'psd2/consent/list';
  }

  async revokeConsent(id) {
    let response = await Api.call(`psd2/consent/${id}/revoke`);
    return response;
  }
}
