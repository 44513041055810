import BaseModel from '@pdl/app/logic/model/BaseModel';

export default class OTPVerifyCodeModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'otp/verify/code';
  }

  getValidationRules() {
    return {
      input: {
        presence: {
          allowEmpty: false,
          message: '^' + 'Please enter a value'
        }
      }
    };
  }
}
