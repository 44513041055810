import React, { Component } from 'react';
import NumberHelper from '@pdl/app/util/NumberHelper';
import DateHelper from '@pdl/app/util/DateHelper';

export default class MerchantTransaction extends Component {

  render() {
    let item = this.props.transaction;
    let amount = null;
    if (item.type === 'spend' && item.amount !== 0) {
      amount = <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>-£{NumberHelper.currency(Math.abs(item.amount, true))}</span>
    } else {
      amount = <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>{NumberHelper.currency(item.amount, true)}</span>
    }
    return (
      <li className={'notificationItem transactionItem statements '}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1, flexGrow: 1 }}>
          <span style={{ minWidth: 40 }}>
            {!item.imag ? <img style={{ width: 40, height: 'auto' }} src={require('../../../../assets/no-image.png')} alt='No-img' /> : <img style={{ width: 40, height: 'auto', borderRadius: 20 }} src={item.image} alt='Merchant' />}
          </span>
          <span className="transaction-title-wrapper" style={{ display: 'flex', flexDirection: 'column', paddingLeft: 3 }}>
            <span className="transaction-title" >{item.name}</span>
            <span className='transaction-date'>{DateHelper.date(item.transactionDate)}</span>
          </span>
        </div>
        <div className="transaction-amount-wrapper" >
          {amount}
        </div>
      </li>
    )
  }
}
