import React from 'react';
import { AppUploadField } from '@pdl/app';

export default class UploadFields extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="create-account-form-wrapper" style={{ marginTop: 10 }}>
        {/* <FormSubmitWrapper formHandler={formHandler} buttonClass='gold-button' to="/" buttonText='Next' > */}
        <div style={{ flex: 1, textAlign: 'center', marginRight: 5 }}>
          <AppUploadField
            name="coverImage"
            buttonLabel="Your ID"
            style={{ width: 230 }}
            params={{
              private: true,
              domain: 'kyc'
            }}
            onChange={files => this.props.onChange(files, 'proof_of_id')}
          >
            <img
              alt="Upload ID"
              style={{ display: 'block' }}
              src={require('@pdl/app/assets/img/upload-id.png')}
            />
          </AppUploadField>
        </div>
        <div style={{ flex: 1, textAlign: 'center', marginLeft: 5 }}>
          <AppUploadField
            name="coverImage"
            buttonLabel="Proof of address"
            style={{ width: 230 }}
            params={{
              private: true,
              domain: 'kyc'
            }}
            onChange={files => this.props.onChange(files, 'proof_od_address')}
          >
            <img
              alt="Upload"
              style={{ display: 'block' }}
              src={require('@pdl/app/assets/img/upload-id.png')}
            />
          </AppUploadField>
        </div>
      </div>
    );
  }
}
