import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';

export default class FaqModel extends BaseModel {
  getListUrl() {
    return 'support/faq/list';
  }

  async getFaq() {
    let faq = await Api.call(this.getListUrl());
    return faq.data;
  }
}
