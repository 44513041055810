import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';

export default class LoginDataModel extends BaseModel {
  // getListUrl() {
  //   'user-device/list'
  // }

  async getLoginData() {
    const result = await Api.call('user-device/list');
    return result.data || [];
  }

  async logOutAllSessions() {
    const response = await Api.call('user-device/remove-all ');
    return response;
  }

  async logOutDevice(id) {
    const response = await Api.call('user-device/remove', {
      id
    });
    return response;
  }
}
