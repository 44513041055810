import React, { Component } from 'react';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '@pdl/app';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import ScaService from '../../../logic/services/ScaService';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import LanguageSwitcher from '@pdl/app/modules/locale/components/LanguageSwitcher';

class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        email: '',
        password: '',
        remember: false
      }
    });
  }

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        email: ' ',
        password: ' '
      }),
      {
        redirect: false
      }
    );
    this.formHandler.on('submitSuccess', (data: { success: any }) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  async _onScaSuccess() {
    await this.formHandler.handleSubmit();
    this.props.history.replace('/');
  }

  onSubmitSuccess(data: { success: any; data: any }) {
    if (data.success) {
      let { nextState } = data.data || {};
      if (nextState) {
        const {
          hash = '',
          token = '',
          sentTo = '',
          retries = '',
          message = ''
        } = nextState.payload || {};
        this.props.history.push('/sca-process', {
          scaActionHash: ScaService.registerAction(() => this._onScaSuccess()),
          fromLogin: true,
          nextState: nextState.name,
          payload: nextState.payload,
          hash: hash,
          token: token,
          request: sentTo,
          retries: retries || 3,
          customerId: this.state.record.customerId,
          password: this.state.record.password,
          message: message
        });
      } else {
        if (data.success && data.data.token) {
          this.props.userLoggedIn(data.data.token, data.data.account);
        }
      }
    }
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { formHandler } = this;
    return (
      <div>
        <div className="verification-image" />
        <div className="signUp-wrapper">
          <Link
            to="/sign-up"
            style={{
              textDecoration: 'none',
              fontSize: 14,
              textAlign: 'center'
            }}
          >
            <Trans>New to Tillo? Sign Up</Trans>
          </Link>
        </div>
        <div className="container login-container">
          <img
            src={require('@pdl/app/assets/variants/tillo/images/logo_blue.png')}
            className="login-logo"
            style={{ maxWidth: 200 }}
            alt="logo"
          />
          <div className="row">
            {/* <div className="col-md">
                  <LoginBanner/>
                </div> */}
            <div className="col-md login-holder">
              <div className="login-box" id="log-in">
                <div style={{ marginTop: 10 }}>
                  <h4
                    style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
                  >
                    <Trans>Sign in</Trans>
                  </h4>
                  <FormSubmitWrapper
                    formHandler={formHandler}
                    buttonClass="gold-button"
                    to="/"
                    buttonText={i18n.t('Log in')}
                    getModalMessage={data => this._getModalMessage(data)}
                  >
                    <div className="login-input-wrapper">
                      {/* <img src={require('@pdl/app/assets/img/user-id-icon.svg')} className="input-icon" /> */}
                      <InputField
                        className="input-group"
                        materialProps={{
                          fullWidth: true
                        }}
                        autoComplete={this.state.record.email}
                        label={i18n.t('Email')}
                        name="email"
                        // placeholder={'Email'}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        value={this.state.record.email}
                        handler={formHandler}
                      />
                    </div>
                    <div className="login-input-wrapper">
                      {/* <img src={require('@pdl/app/assets/img/login-password-icon.svg')} className="input-icon" /> */}
                      <InputField
                        className="input-group"
                        materialProps={{
                          fullWidth: true
                        }}
                        autoComplete={this.state.record.password}
                        label={i18n.t('Enter your password')}
                        type="password"
                        name="password"
                        // placeholder={'Enter your password'}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}

                        value={this.state.record.password}
                        handler={formHandler}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 20
                      }}
                    >
                      <Link
                        to="/forgot-password"
                        style={{
                          color: 'gray',
                          textDecoration: 'none',
                          fontWeight: 'lighter',
                          fontSize: 14
                        }}
                      >
                        <Trans>Forgot password?</Trans>
                      </Link>
                    </div>
                  </FormSubmitWrapper>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LanguageSwitcher
                  // listBorderStyle={`1px solid black`}
                  />
                </div>
                {/*   <div style={{ textAlign: 'left', margin: '10px 0 10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                         <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                          <div className="squaredTwo">
                            <input type="checkbox" value="None" id="squaredTwo" name="check" onChange={(e) => this.formHandler.handleInputChange('remember', e.nativeEvent.target.checked)} />
                            <label htmlFor="squaredTwo"></label>
                          </div>
                          <label htmlFor="squaredTwo" style={{ cursor: 'pointer', color: 'white', fontWeight: 'lighter', fontSize: 14, paddingLeft: 10, marginBottom: 0 }}>Remember me</label>
                        </div>
                      </div> */}
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, width: '100%' }}>
                        <Link to="/forgot-password" style={{ opacity: 0, color: '#424242', textDecoration: 'none', fontWeight: 'lighter', fontSize: 16 }}> New User? </Link>
                      </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload: { token: any } }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
