import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import i18n from 'i18n';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(0,0,0,1)' }
  },
  // For Underline Hover Color
  overrides: {
    MuiOutlinedInput: {
      input: {
        // padding: '10px!important',
        border: 'none!important'
      }
    },
    MuiInputLabel: {
      outlined: {
        // transform: 'translate(14px, 14px) scale(1)'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none !important'
        },
        '&:after': {
          borderBottom: 'none !important'
        }
      }
    }
  }
});

export default class InputField extends Component<any, any> {
  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    const classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e: any) {
    this.props.handler.handleInputChange(e);
  }

  getValidationError() {
    const messages: any[] = [];

    if (this.props.handler) {
      this.props.handler
        .getErrors()
        .filter((x: { field: any }) => x.field === this.props.name)
        .forEach((error: { message: any }) =>
          messages.push(i18n.t(error.message))
        );
    }
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${this.props.name}-error`}>
        {messages.map(x => (
          <div>{x}</div>
        ))}
      </div>
    );
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    // let className = this.getClassName();
    const validationError = this.getValidationError();

    return (
      <div
        className={this.props.className}
        style={{ position: 'relative' }}
        id={validationError ? 'outlined-error' : ''}
      >
        <MuiThemeProvider
          theme={this.props.muiTheme ? this.props.muiTheme : theme}
        >
          <TextField
            error={!!validationError}
            ref="actualInput"
            variant="outlined"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            className={this.props.className}
            type={this.props.type}
            placeholder={this.props.placeholder}
            name={this.props.name}
            inputProps={this.props.inputProps}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            value={this.props.value}
            onChange={this.handleInputChange.bind(this)}
            {...this.props.materialProps}
          />
        </MuiThemeProvider>
        {validationError}
      </div>
    );
  }
}
