import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppRoute, AppRouter } from '@pdl/app/components';
import Modals from '@pdl/app/modules/modals/Modals';
import PublicLayout from './components/layout/PublicLayout';
import DefaultLayout from './components/layout/DefaultLayout';
import { appMountedAction } from './logic/store/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';
import SCAWrapper from './modules/sca/SCAWrapper';
import AuthorizationLogin from './modules/auth/pages/AuthorizationLogin';

// import  AuthService  from '../app/logic/services';

class App extends Component<any, any> {
  componentDidMount() {
    this.props.appMounted();
    this.props.refreshAuth();

    // AuthService.poolStatusUpdate();
  }

  getInnerPaths(props: { match: { path: any } }) {
    // if(props.match.params && props.match.params.locale) {
    //   locale = props.match.params.locale;
    // }

    let url = props.match.path;
    url = url.replace(/\/$/, '');

    return (
      <Switch>
        <AppRoute
          path={`${url}/login`}
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/forgot-password"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path={'/:locale?/auth-login'}
          component={AuthorizationLogin}
        />
        <AppRoute path={'/:locale?/sca-process'} component={SCAWrapper} />
        <AppRoute
          path={`/user-consent`}
          load={() => import('./modules/sca/consent/UserConsent')}
        />
        <AppRoute
          path="/sign-up"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/sent-link"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/create-account/*"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute
          path="/upload-photo"
          component={PublicLayout}
          unAuthenticatedOnly
        />
        <AppRoute path="/finish" component={PublicLayout} unAuthenticatedOnly />
        {/* <AppRoute path="/welcome" component={PublicLayout} unAuthenticatedOnly /> */}
        <AppRoute
          path="/order-card"
          load={() => import('@pdl/app/modules/order-card/OrderCard')}
          authenticatedOnly
        />
        <AppRoute
          path="/top-up"
          load={() => import('@pdl/app/modules/order-card/TopUpAccount')}
          authenticatedOnly
        />
        <AppRoute
          path="/accept-terms"
          load={() =>
            import('@pdl/app/modules/order-card/OrderCardAcceptTerms')
          }
          authenticatedOnly
        />
        <AppRoute
          path="/enter-card-details"
          load={() => import('@pdl/app/modules/order-card/EnterCardDetails')}
          authenticatedOnly
        />
        <AppRoute
          path="/create-card-pin"
          load={() => import('@pdl/app/modules/order-card/CreateCardPin')}
          authenticatedOnly
        />
        <AppRoute
          path="/confirm-card-pin"
          load={() => import('@pdl/app/modules/order-card/ConfirmCardPin')}
          authenticatedOnly
        />
        <AppRoute
          path="/success"
          load={() => import('@pdl/app/modules/order-card/Success')}
          authenticatedOnly
        />
        <AppRoute path="" component={DefaultLayout} authenticatedOnly />
      </Switch>
    );
  }

  render() {
    if (!this.props.ready) return null;

    const InnerRouting = (props: any) => this.getInnerPaths(props);

    return (
      <AppRouter>
        <div className="App">
          <Switch>
            <Route path="/" component={InnerRouting} />
          </Switch>
          <div id="modalHolder" className="modal-holder">
            <Modals />
          </div>
        </div>
      </AppRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: null }): void;
  (arg0: { type: string }): void;
}) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    }
  };
};

const mapStateToProps = (state: { ready: any }) => {
  return {
    ready: state.ready
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
