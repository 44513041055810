/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment';
import AppSelect from '@pdl/app/components/elements/form/AppSelect';
import { FormSubmitWrapper, FormHandler, InputField } from '@pdl/app';
import AppNumberField from '@pdl/app/components/elements/form/AppNumberField';
import CountryModel from '@pdl/app/logic/model/CountryModel';
import AuthService from '@pdl/app/logic/services/AuthService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import LocationService from '@pdl/app/logic/services/location/LocationService';
import EditProfileFormModel from '../models/EditProfileFormModel';

const selectStyle = {
  paddingLeft: 0
};

class EditMyPersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // date: moment().format('DD-MM-YYYY'),
      textHidden: true
    };

    const { profile } = props;

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new EditProfileFormModel({
        // email: profile.email || '',
        // title: profile.title || '',
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        // birthDate: moment(profile.birthDate).format('YYYY-MM-DD') || '',
        addressOne: profile.addressOne || '',
        addressTwo: profile.addressTwo || '',
        houseNameNumber: profile.houseNameNumber || '',
        city: profile.city || '',
        postCode: profile.postCode || '',
        countryId: profile.countryId,
        mobile: profile.mobile || ''
        // addressReference: null
      }),
      {
        countries: [],
        changeRequestSubmitted: false,
        title: EditProfileFormModel.getTitles()
      }
    );
  }

  componentDidMount() {
    LocationService.getCountries()
      .then(countries => {
        if (!countries) countries = [];
        const defaultCountry = countries.find(item => item.isDefault);
        this.setState({ countries, selectedItem: defaultCountry });
      })
      .catch();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const stateUpdate = {};
    if (nextProps.profile.changeRequestId) {
      stateUpdate.changeRequestSubmitted = true;
    }

    return stateUpdate;
  }

  _submitForm(e) {
    e.preventDefault();

    const valid = this.formHandler.validate();

    if (!valid) {
      return;
    }

    let country = null;

    if (this.state.record.countryId !== '') {
      country = this.state.countries.find(
        x => x.id === this.state.record.countryId
      );
    }

    const countryCode = country && `+${country.phoneCode}`;
    const countryCodeLength = this.state.record.mobile.substring(
      0,
      countryCode.length
    );

    // if (!this.state.record.mobile.includes(countryCode)) {
    if (countryCode !== countryCodeLength) {
      ToastHelper.show(
        `${i18n.t('Please enter valid phone code')} ${countryCode}`,
        'error'
      );
      return;
    }

    const changed = new EditProfileFormModel().compareProfileFields(
      this.props.profile,
      this.state.record
    );

    if (changed) {
      return new Promise(resolve => {
        this.formHandler
          .handleSubmit()
          .then(data => {
            if (data.success) {
              ToastHelper.success('Changes saved!');
              window.scrollTo(0, 0);
              if (data.success) {
                AuthService.refreshAuth();
              }
              resolve(data.success);
            } else {
              resolve(false);
            }
          })
          .catch(e => resolve(false));
      });
    }
  }

  _printItem(label, value) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: 16
        }}
      >
        <p
          style={{ fontSize: 14, color: 'rgba(7,39,45,0.4)', marginBottom: 4 }}
        >
          <Trans>{label}</Trans>
        </p>
        <p style={{ textAlign: 'center', fontSize: 16, color: '#4F4B61' }}>
          {value}
        </p>
      </div>
    );
  }

  render() {
    if (this.state.changeRequestSubmitted && this.props.profile.changeRequest) {
      const profile = this.props.profile.changeRequest;

      const address = [
        profile.addressOne,
        profile.addressTwo,
        profile.postCode,
        profile.city
      ]
        .filter(x => x && x.length > 0)
        .join('\r\n');
      return (
        <div className="container">
          <div className="pageHeader">
            <h3 className="pageTitle">
              <Trans>Edit Personal Data</Trans>
            </h3>
          </div>
          <div
            style={{
              flex: 1,
              flexGrow: 1,
              paddingTop: 32,
              paddingBottom: 32,
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: 'white'
            }}
          >
            <div
              style={{
                width: '100%',
                flexDirection: 'row',
                padding: 10,
                marginBottom: 16,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <p style={{ textAlign: 'center', fontSize: 16 }}>
                <Trans>
                  You have already submitted a profile change request. Please
                  stay tuned as we verify your changes. Changed data is shown
                  below.
                </Trans>
              </p>
            </div>
            {/* this._printItem('Title', profile.title) */}
            {this._printItem(i18n.t('Name'), profile.fullName)}
            {/* this._printItem(
              'Date of birth',
              moment(profile.birthDay).format('YYYY-MM-DD')
            ) */}
            {this._printItem(i18n.t('Mobile number'), profile.mobile || ' - ')}
            {this._printItem(i18n.t('Address'), address)}
          </div>
        </div>
      );
    }

    const { profile } = this.props;

    if (!profile) return null;
    const { formHandler } = this;

    return (
      <div className="appContainer">
        <div className="container">
          <div className="pageHeader">
            <h3 className="pageTitle">
              <Trans>My Personal Data</Trans>
            </h3>
          </div>
          <p className="note">
            <span>
              <Trans>
                Any changes to this information is subject to additional KYC
                checks which could prevent use of your card until complete.
              </Trans>
            </span>
          </p>

          <FormSubmitWrapper
            changeSubmitButtonWrapper="change-submit-btn-wrapper"
            buttonContainer="custom-form-btn-container"
            buttonClass="gold-button"
            formHandler={formHandler}
            buttonText={i18n.t('Save')}
            onSubmit={e => this._submitForm(e)}
          >
            <div className="row">
              <div className="col-xl-6">
                {/* <div className="login-input-wrapper">
                  <AppSelect
                    materialProps={{
                      fullWidth: true
                    }}
                    className="input-group"
                    label={i18n.t('Title')}
                    options={this.state.title}
                    name="title"
                    value={this.state.record.title}
                    handler={formHandler}
                  />
                  </div> */}
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('First Name')}
                    type="text"
                    name="firstName"
                    value={this.state.record.firstName}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('Address Line One')}
                    type="text"
                    name="addressOne"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.addressOne}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      min: 0,
                      step: 1
                    }}
                    label={i18n.t('House number/name')}
                    type="text"
                    name="houseNameNumber"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.houseNameNumber}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('Post code')}
                    type="text"
                    name="postCode"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.postCode}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    format="+##############"
                    // mask="_"
                    label={i18n.t('Mobile number')}
                    type="text"
                    name="mobile"
                    value={this.state.record.mobile}
                    handler={formHandler}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('Last Name')}
                    type="text"
                    name="lastName"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.lastName}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('Address Line Two')}
                    type="text"
                    name="addressTwo"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.addressTwo}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    label={i18n.t('City/Town')}
                    type="text"
                    name="city"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.record.city}
                    handler={formHandler}
                  />
                </div>
                <div className="login-input-wrapper">
                  <AppSelect
                    materialProps={{
                      fullWidth: true
                    }}
                    className="input-group"
                    label={i18n.t('Country')}
                    model={CountryModel}
                    name="countryId"
                    value={this.state.record.countryId}
                    handler={formHandler}
                  />
                </div>

                {/* <div className="login-input-wrapper">
                  <InputField
                    className="input-group"
                    materialProps={{
                      fullWidth: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={i18n.t('Date of birthday')}
                    type="date"
                    name="birthDate"
                    inputProps={{
                      max: moment(
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        )
                      ).format('YYYY-MM-DD')
                    }}
                    value={this.state.record.birthDate}
                    handler={formHandler}
                  />
                  </div> */}
              </div>
            </div>
          </FormSubmitWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(EditMyPersonalData);
