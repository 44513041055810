// import Api from '../services/Api';
import AppStore from '@pdl/app/logic/store/AppStore';
import i18next from '../../../../i18n';
// import LocalStorage from '../components/local-storage/local-storage.component';
import { languageChangeAction } from './LocalizationActions';

export default class LocalizationService {
  static async getLocale() {
    if (AppStore) {
      const state = AppStore.getState();

      if (state && state.localization.locale) {
        return state.localization.locale;
      }
    }

    return 'en';
  }

  static async initLocale() {
    const locale = await localStorage.getItem('LOCALE');
    if (locale) {
      this.setLocale(locale);
    }
  }

  static getDefaultLocale() {
    return 'en';
  }

  static setLocale(locale) {
    // Store locale in local storage
    localStorage.setItem('LOCALE', locale);

    // Change i18n locale
    i18next.changeLanguage(locale);

    // Update state
    AppStore.dispatch(languageChangeAction(locale));
  }

  static translate(key) {
    const dictionary = LocalizationService.getDictionary();
    const locale = LocalizationService.getLocale();

    if (dictionary[key] && dictionary[key][locale]) {
      return dictionary[key][locale];
    }

    return key;
  }

  static getDictionary() {
    // const state = appStore ? appStore.getState() : null;

    // if (!state) return {};

    // if (state.localization.dictionary) {
    //   return state.localization.dictionary;
    // }

    return {};
  }

  static async loadDictionary() {
    // const response = await Api.call('translation/dictionary');
    // // return;
    // for (const locale in response.data) {
    //   i18next.addResourceBundle(locale, 'translation', response.data[locale]);
    // }
    // return;
    // if (response.success) {
    //   appStore.dispatch({
    //     type: 'DICTIONARY_LOADED',
    //     payload: {
    //       dictionary: response.data
    //     }
    //   });
    // }
  }
}
