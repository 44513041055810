import BaseModel from '@pdl/app/logic/model/BaseModel';
import Api from '@pdl/app/logic/api/Api';
import Config from '@pdl/app/config';

export default class PaymentCardModel extends BaseModel {

  async addCard(user, pan, expiry, cvv) {
    const data = {
      type: 'card',
      number: pan.replace(/[^0-9]/g, ''),
      name: user.fullName,
      cvv,
      expiry_month: expiry.split('/')[0],
      expiry_year: `${expiry.split('/')[1]}`
    };

    const requestOptions = {
      headers: {
        Authorization: Config.checkout_public_key,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data)
    };

    const response = await fetch(Config.checkout_url, requestOptions);
    
    const actualResponse = await response.json();
    
    if (!actualResponse || !actualResponse.token) {
      throw new Error('Card data invalid!');
    }

    const { token } = actualResponse;
    const apiResponse: any = await Api.call('payment-card/create', {
      token
    });

    return apiResponse.data && apiResponse.data.redirect
      ? apiResponse.data.redirect
      : apiResponse.success;
  }
}
