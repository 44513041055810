import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import LoginPage from '@pdl/app/modules/auth/pages/LoginPage';
import ForgotPassword from '@pdl/app/modules/auth/pages/ForgotPassword';
import SignUpPage from '@pdl/app/modules/auth/pages/sign-up/SignUpPage';
import SentLink from '@pdl/app/modules/auth/pages/sign-up/SentLink';
import CreateAccount from '@pdl/app/modules/auth/pages/sign-up/CreateAccount';
import { AppRoute } from '@pdl/app';
import withLocalization from '@pdl/app/modules/locale/components/withLocalization';

class PublicLayout extends Component<any, any> {
  match: any;

  constructor(props: any) {
    super(props);
    this.match = props.match;
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      // this.props.history.push('/dashboard')
    }
  }

  render() {
    return (
      <div className="public-layout">
        {/* <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/" component={WelcomePage} /> */}
        <Switch>
          <AppRoute path="/login" component={LoginPage} />
          <AppRoute path="/forgot-password" component={ForgotPassword} />
          <AppRoute path="/sign-up" component={SignUpPage} />
          <AppRoute path="/sent-link" component={SentLink} />
          <AppRoute path="/create-account/:hash" component={CreateAccount} />
          {/* <AppRoute path="/" component={WelcomePage} /> */}
        </Switch>
      </div>
    );
  }
}

export default withLocalization(PublicLayout);
