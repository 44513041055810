import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppValidationErrors from './AppValidationErrors';

export default class AppCheckboxInput extends Component<any, any> {
  static defaultProps: any = { onChange: () => null, style: {} };

  constructor(props: any) {
    super(props);

    this.state = {
      value: props.value,
      id: props.id ? props.id : uuidv4()
    };
  }

  getErrors() {
    if (this.props.hideValidationErrors) return null;

    const messages: any[] = [];

    this.props.handler
      .getErrors()
      .filter((x: { field: any }) => x.field === this.props.name)
      .forEach((error: { message: any }) => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div
        className="validation-message checkbox-error"
        key={`${this.props.name}-error`}
      >
        {messages.map(x => (
          <div>{x}</div>
        ))}
      </div>
    );
  }

  /**
   * Toggle checkbox value
   */
  _toggleValue() {
    this.setState(
      {
        value: !this.state.value
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            this.state.value
          );
          if (this.props.validateOnChange) {
            this.props.handler.validateField(this.props.name, this.state.value);
          }
        }

        this.props.onChange(this.state.value);
      }
    );
  }

  render() {
    return (
      <div>

        <div
          style={{
            ...{
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              position: 'relative'
            },
            ...this.props.style
          }}
        >
          <div
            className="squaredTwo"
            style={{ margin: 0, minWidth: 20, maxWidth: 20, height: 20 }}
          >
            <input
              type="checkbox"
              id={this.state.id}
              name={this.props.name}
              checked={this.props.checked}
              onChange={() => this._toggleValue()}
            />
            <label
              htmlFor={this.state.id}
              style={{ width: 12, height: 12, top: 0, left: 0, margin: 0 }}
            />
          </div>
          <label
            htmlFor={this.state.id}
            style={{
              cursor: 'pointer',
              color: '#9B9A9B',
              fontWeight: 'lighter',
              fontSize: 14,
              paddingLeft: 10,
              marginBottom: 0
            }}
          >
            {this.props.labelText}
          </label>
        </div>
        <AppValidationErrors name={this.props.name} handler={this.props.handler}/>
      </div>
    );
  }
}
