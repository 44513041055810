import Api from "../api/Api";

export default class BaseModel {

  constructor(data = null) {
    this.data = data;
  }

  getListUrl() {
    return null;
  }

  getListRequestData(params) {
    return params;
  }

  getDetailsUrl() {
    return null;
  }

  getDetailsRequestData(id) {
    return {
      id
    }
  }

  getEmptyRecord() {
    return {};
  }

  getData() {
    if (this.data) {
      return this.data;
    }
    return this.getEmptyRecord();
  }


  async fetch(params = {}) {
    let result = await Api.call(
      this.getListUrl(),
      this.getListRequestData(params)
    );

    return result.data;
  }

  async fetchFlat(params = {}) {
    let result = await Api.call(
      this.getListUrl(),
      { ...this.getListRequestData(params), flat: true }
    );

    return result.data;
  }

  async get(id) {
    let result = await Api.call(
      this.getDetailsUrl(),
      this.getDetailsRequestData(id)
    );

    return result.data;
  }

  /**
* Get validation rules
*/
  getValidationRules() {
    return {};
  }

  /**
   * Custom (model specific) validation
   */
  customValidation(record) {
    return [];
  }

}
