import {
  MEMBER_AUTH_UPDATE_STORE,
  MEMBER_AUTH_LOADED
} from '../auth/AuthActions';

export function paymentCards(state = [], action) {
  switch (action.type) {
    case MEMBER_AUTH_UPDATE_STORE:
    case MEMBER_AUTH_LOADED:
      return action.payload.paymentCards || [];
    default:
      return state;
  }
}

export default paymentCards;
