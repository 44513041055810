import BaseModel from '@pdl/app/logic/model/BaseModel';

export default class AuthorizationLoginModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/login/sca';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      email: {
        presence: {
          message: '^Please enter a valid customer number'
        }
      },

      password: {
        presence: {
          message: '^Please enter a password'
          // },
          // length: {
          //   minimum: 5,
          //   message: '^Your password must be at least 5 characters long'
        }
      }
    };
  }
}
