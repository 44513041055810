import PasswordService from '@pdl/app/logic/services/PasswordService';
import BaseModel from '@pdl/app/logic/model/BaseModel';

export default class ChangePasswordFormModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/password-change';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      oldPassword: {
        presence: {
          allowEmpty: false,
          message: '^Please enter old password'
        }
      },
      repeatedPassword: {
        presence: {
          allowEmpty: false,
          message: '^Please repeat new password'
        },
        equality: {
          attribute: 'password',
          message: '^Passwords dont match'
        }
      }
    };
  }

  customValidation(record) {
    const errors = [];
    const pswd = record.password;

    try {
      const result = PasswordService.validatePassword(pswd);
    } catch (e) {
      return [
        {
          field: 'password',
          message: e.message
        }
      ];
    }

    return errors;
  }
}
