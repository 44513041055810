import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class SideMenuItem extends Component {
  /**
   * Set initial state in constructor
   */
  constructor() {
    super();
    this.state = {
      opened: false
    };
  }

  /**
   * Called on link click
   */
  linkClick() {
    window.scrollTo(0, 0);
    this.setState({
      opened: !this.state.opened
    });
  }

  /**
   * Check if path is active
   *
   * @param item
   * @returns {boolean}
   */
  isPathActive(item) {
    // Get current path from context
    // let currentPath = this.context.router.route.location.pathname;
    const currentPath = window.location.pathname;

    // If paths same?
    if (currentPath === item.path) {
      return true;
    }

    // Check if some child is active
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        const childActive = this.isPathActive(item.children[i]);

        if (childActive) {
          return true;
        }
      }
    }

    // Default false
    return false;
  }

  /**
   * Render function
   *
   * @returns {XML}
   */
  render() {
    // Build icon
    // const icon = `fa fa-${this.props.icon}`;

    // Build children
    const children = [];
    if (this.props.children) {
      this.props.children.map((child, index) => {
        const key = `side-menu-item-${index}`;
        children.push(<SideMenuItem {...child} key={key} />);
      });
    }

    // Get children components
    let childPart = '';
    const { path } = this.props;
    if (children.length) {
      childPart = <ul className="sub-nav">{children}</ul>;
    }

    // Check if item is active
    const isActive = this.isPathActive(this.props);

    // Check if item should be opened
    const opened = true;
    // if(isActive) {
    //   opened = true;
    // }

    let innerComponent = null;

    if (path) {
      innerComponent = (
        <Link to={path} onClick={this.linkClick.bind(this)}>
          <img src={this.props.icon} style={{maxWidth: 30}} />
          {/* <span>{this.props.label}</span> */}
        </Link>
      );
    } else {
      innerComponent = (
        <a href="javascript:void(0)" onClick={this.linkClick.bind(this)}>
          <img src={this.props.icon} style={{maxWidth: 30}} />
          {/* <span>{this.props.label}</span> */}
        </a>
      );
    }

    // Render item
    return (
      <li className={isActive ? 'active' : null}>
        {isActive ? <div className="active-blue-line" /> : null}
        {innerComponent}
        {opened ? childPart : null}
      </li>
    );
  }
}

SideMenuItem.contextTypes = {
  router: PropTypes.object
};
