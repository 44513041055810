import React from 'react';
import { AppButton } from '@pdl/app';
import { Trans } from 'react-i18next';
import AppImage from '@pdl/app/components/images/AppImage';
import { connect } from 'react-redux';
import NumberHelper from '@pdl/app/util/NumberHelper';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import AuthService from '@pdl/app/logic/services/AuthService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import SendMoneyService from '../SendMoneyService';
import i18n from '../../../../../i18n';

class ConfirmTransfer extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.state = {};
  }

  _confirmTransfer = async hash => {
    LoadingHelper.executeAction(
      () => {
        return this._actualTransferConfirmation(hash);
      },
      {
        successMessage: i18n.t('You have successfully sent money.'),
        loadingMessage: i18n.t('Sending money...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: false,
        beforeClose: () => {
          AuthService.refreshAuth();
        },
        afterClose: () => {
          this.props.nextStep();
        }
      }
    );
  };

  _actualTransferConfirmation = async hash => {
    try {
      const transfer = await SendMoneyService.confirmTransfer(hash);
      this.props.successTransfer(transfer);
      AuthService.refreshAuth();
    } catch (e) {
      ToastHelper.show(e.message, 'error');
    }
    return true;
  };

  render() {
    const { data } = this.props;

    return (
      <>
        <div className="d-flex align-items-center flex-column justify-content-center pt-3">
          <AppImage
            src={
              data.user.image
                ? data.user.image
                : require('@pdl/app/assets/profile-image-dummy.png')
            }
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
              marginBottom: 10
            }}
          />
          <h5>
            <Trans>Send money to</Trans>
          </h5>
          <h4
            style={{
              textAlign: 'center',
              fontSize: 20
            }}
          >
            {data.user.fullName}
          </h4>

          <h5 className="pb-3">
            <Trans>Amount: </Trans>
          </h5>
          <h1 className="pb-3" style={{ fontSize: 40 }}>
            {NumberHelper.currency(data.amount, data.currency)}
          </h1>
          {data.note ? (
            <h5 className="pb-5">
              <Trans>NOTE: </Trans> {data.note}
            </h5>
          ) : null}
        </div>

        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: 100 }}
        >
          <AppButton onClick={() => this._confirmTransfer(data.hash)}>
            <Trans>Confirm</Trans>
          </AppButton>
          <AppButton onClick={() => this.props.previousStep()}>
            <Trans>Back</Trans>
          </AppButton>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(ConfirmTransfer);
