import Api from '@pdl/app/logic/api/Api';

export default class SendMoneyService {
  static initTransfer(account) {
    this.currentTransfer = {
      account,
      currency: null,
      transferToAccount: null,
      amount: null,
      note: null
    };
  }

  static updateTransfer(data = {}) {
    this.currentTransfer = {
      ...this.currentTransfer,
      ...data
    };
  }

  static getTransfer() {
    return this.currentTransfer;
  }

  static sendTransfer() {
    this.currentTransfer = null;
  }

  static cancelTransfer() {
    this.currentTransfer = null;
  }

  static async triggerTransfer(user, currency, amount, note) {
    const response = await Api.call('transfer/trigger', {
      userId: user.id,
      customerReference: user.customerReference,
      currency,
      amount,
      message: note
    });

    if (!response.success) {
      throw new Error(response.message);
    }

    return response.data.hash;
  }

  static async confirmTransfer(hash) {
    const response = await Api.call('transfer/confirm', {
      hash
    });

    if (!response.success) {
      throw new Error(response.message);
    }

    return response.data;
  }
}
