import React from 'react';
import { AppButton } from '@pdl/app';
import { Trans } from 'react-i18next';
import AppImage from '@pdl/app/components/images/AppImage';
import { connect } from 'react-redux';
import NumberHelper from '@pdl/app/util/NumberHelper';
import { Link } from 'react-router-dom';

class SuccessComponent extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.state = {};
  }

  render() {
    const { transfer } = this.props;
    return (
      <>
        <h4 className="text-center">
          <Trans>You’ve successfully sent</Trans>
        </h4>
        <h1 className="text-center" style={{ fontSize: 40 }}>
          {NumberHelper.currency(transfer.amount, transfer.currency)}
        </h1>
        <h4 className="text-center">
          <Trans>to</Trans>
        </h4>
        <div className="d-flex align-items-center flex-column justify-content-center">
          <AppImage
            src={
              transfer.toUser && transfer.toUser.imagePath
                ? transfer.toUser.imagePath
                : require('@pdl/app/assets/profile-image-dummy.png')
            }
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
              marginBottom: 10
            }}
          />
          <h4
            style={{
              textAlign: 'center',
              fontSize: 20
            }}
          >
            {transfer.toUser.fullName}
          </h4>
        </div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: 100 }}
        >
          <AppButton onClick={() => this.props.anotherTransfer()}>
            <Trans>Make another transfer</Trans>
          </AppButton>
          <Link to="/card" className="gold-button">
            <Trans>Close</Trans>
          </Link>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(SuccessComponent);
