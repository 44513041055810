import axios from 'axios';
import Logger from '../../util/Logger';
import store from '../store/AppStore';
import Config from '../../config';

export default class Api {
  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static call(
    method: string | number,
    data: any = null,
    onProgress: any = null
  ) {
    const authToken = store.getState().auth.token || null;

    const requestUrl = this.getBaseUrl() + method;
    const ajaxOptions: any = {
      url: this.getBaseUrl() + method,
      method: 'POST',
      headers: {},
      onUploadProgress(progressEvent: any) {
        if (onProgress) {
          onProgress(progressEvent);
        }
      }
    };

    if (data instanceof FormData) {
      ajaxOptions.data = data;
      ajaxOptions.headers['Content-Type'] = 'multipart/form-data';
    } else {
      ajaxOptions.headers['Content-Type'] = 'application/json';
      ajaxOptions.contentType = 'application/json';
      ajaxOptions.data = JSON.stringify(data);
    }

    if (authToken !== null) {
      ajaxOptions.headers.Authorization = `Bearer ${authToken}`;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //     console.log(percentCompleted)
      //   }
      // }

      axios(ajaxOptions)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error.response) {
            Logger.log('data', error.response);

            if (error.response.status === 403) {
              localStorage.removeItem('AUTH_TOKEN');
              window.location.reload();
              // store.dispatch(logoutTriggerAction());
            }

            if (error.response.status === 200) {
              const { code } = error.response.data;

              if (code === 401 || code === 403) {
                localStorage.removeItem('AUTH_TOKEN');
                window.location.reload();
                // store.dispatch(logoutTriggerAction())
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
          reject(error.response ? error.response.data : error);
        });
    });
  }
}
